.navbar {
	padding-bottom: 1px;
	position: sticky;
	top: 0;
	z-index: 99990;
	padding: 0px 30px;
}
.header {
	box-shadow: 0 1px 7px -1px rgba(60, 60, 60, 0.2);
	padding: 23px;
}

.navbar-items-container {
	margin-left: 390px;
	display: flex;
	justify-content: center;
	gap: 20px;
}

.navbar-item {
	font-weight: 500 !important;
}

.navbar-item:hover {
	color: red !important;
}

/* logo */
.navbar-logo-container {
	padding-left: 140px;
	display: flex;
	position: absolute;
	justify-content: center;
}

.logo {
	margin-top: -10px;
}

.navbar-icons-container {
	gap: 30px;
	display: flex;
	justify-content: center;
}

.navbar-icons {
	cursor: pointer;
}
.model-init {
	position: relative;
}

.modal-container {
	backdrop-filter: blur(8px);
	position: fixed;
	top: 0;
	right: 0;
	z-index: 999;
	width: 100vw;
	height: 100vh;
	display: none;
	align-items: center;
	justify-content: center;
}

.modal {
	background-color: white;
	height: fit-content;
	width: fit-content;
	border-radius: 10px;
	backdrop-filter: blur(2px);
	/* position: absolute; */
	/* width: 100vw; */
}

.close-icon {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	font-size: 20px;
	z-index: 2;
	color: #c20a0a; /* Adjust color as needed */
}

button {
	padding: 10px 20px;
	border: none;
	background-color: #007bff;
	color: white;
	border-radius: 5px;
	cursor: pointer;
}
