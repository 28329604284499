.powering-top-container {
	display: flex;
	justify-content: space-between;
	text-align: left;
	gap: 10px;
	background-color: #000000;
	color: #ffffff !important;
}

.powering-text {
	display: flex;
	flex-direction: column;
	justify-content: left;
	text-align: left;
	gap: 10px;
}

.image-section {
	width: 130px;
}

.powering-fourth-title {
	font-size: 48px;
	line-height: 70px;
	font-weight: 600;
	color: #fff;
	font-family: AvenirNextLTPro;
}

.about-fourth-description {
	font-size: 17px !important;
	line-height: 30px;
	font-weight: 600;
	color: #fff;
	font-family: AvenirNextLTPro;
	margin-top: 10px;
	text-align: justify;
}

/* .about-founder-container {
    background-image: url('/public/images/founder background.png');  
    background-size: cover;
    height: 313px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.about-founder-title {
    font-size: 50px;
    line-height: 70px;
    font-weight: 700;
    color: #FFF;
    font-family: AvenirNextLTPro;    
}

.about-founder-description {
    font-size: 20px;
    line-height: 34px;
    font-weight: 400;
    color: #FFF;
    font-family: AvenirNextLTPro;
    margin-top: 15px;
}

.about-bottom-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-second-left-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.about-second-title {
    font-size: 47px;
    font-weight: 700;
}

.about-second-description {
    letter-spacing: 0.4px;
    line-height: 27px;
}

.about-second-right-container {
    display: flex;
    justify-content: center;
    align-items: center;
} */

@media screen and (min-width: 200px) {
	.powering-fourth-title {
		font-size: 28px;
		line-height: 35px;
	}
	.powering-top-container {
		padding: 10% 10%;
	}
	/* .about-founder-title{
        font-size: 40px;
    } */
	.vector12 {
		width: 320px !important;
		margin-bottom: 10%;
	}
}

@media screen and (min-width: 576px) {
	.powering-top-container {
		padding: 10% 24%;
	}

	.vector12 {
		width: 600px !important;
		margin-bottom: 5%;
	}
}

@media screen and (min-width: 860px) {
	/* .about-founder-section-container {
        padding: 5% 10% 2%;
    }
    .about-founder-container {
        padding: 6% 8%;
    } */
	.powering-top-container {
		padding: 3% 8%;
	}

	.vector12 {
		width: 900px !important;
		margin-bottom: 0%;
	}
}
