.SideMenu .ant-menu {
	border: none !important;
}

.sideMenuVertical .ant-menu-item {
	margin: 10px 0 !important;
}
.ant-collapse,
.ant-collapse-item {
	background-color: #000000 !important;
}

.sideMenuVertical .ant-menu-item-selected {
	border: none;
	border-radius: 0px;
	font-weight: 600;
	background: linear-gradient(to right, #d7f8ed, #aca9ff00);
}

.sideMenuVertical .ant-menu-item:hover {
	border: none;
	border-radius: 0px;
	font-weight: 600;
	background: linear-gradient(to right, #d7f8ed, #aca9ff00);
}

.sideMenuVertical .ant-menu-item-selected .ant-menu-title-content {
	/* color: #04B2A9; add this color when svg color change fixed */
	color: #04b2a9;
}

.sideMenuVertical .ant-menu-item-selected .menu-icon {
	height: 20px;
	width: 20px;
	margin-right: 10px;
}

.sideMenuVertical .ant-menu-item-selected svg path {
	fill: #04b2a9 !important;
}

.sideMenuVertical .ant-menu-title-content {
	font-family: AvenirNextLTPro;
	font-size: 15px;
	color: #848397;
	margin-top: 5px;
}

.sideMenuVertical .menu-icon {
	height: 20px;
	width: 20px;
	margin-right: 10px;
}

.menu-order-icon {
	font-size: 18px !important;
}

.layout-section {
	background-color: #ffffff;
	border-radius: 10px;
	padding: 20px 40px;
}

.title-text {
	color: #ffffff !important;
	font-size: 24px !important;
	font-weight: 600;
	font-family: AvenirNextLTPro;
}

.title-help-text {
	color: #ececec;
	font-family: AvenirNextLTPro;
	font-size: 14px;
	margin-bottom: 30px;
}

.fill-btn {
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);
	color: rgb(0, 0, 0);
	height: 50px;
	width: 180px;
	font-family: AvenirNextLTPro;
	font-weight: 600;
	border-radius: 10px;
}

.fill-btn:hover {
	color: #000000 !important;
	border-color: #04b2a9 !important;
}

.order-details-text {
	font-family: AvenirNextLTPro;
	font-weight: 600;
	color: #ffffff;
}

.order-item-title {
	font-family: AvenirNextLTPro;
	font-weight: 600;
	color: #ffffff;
	font-size: 20px;
}

.basic-btn {
	border: 1px solid #e3e3e3;
	border-radius: 5px;
	width: fit-content;
	text-align: center;
	padding: 7px 20px;
	font-family: AvenirNextLTPro;
	color: #000000;
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);
}

.basic-btn:hover {
	border-color: #04b2a9 !important;
}

.order-item-status {
	font-family: AvenirNextLTPro;
	font-weight: 600;
	color: #ffffff;
	font-size: 16px;
}

.give-rating-form .ant-form-item-label,
.give-rating-form .ant-form-item-explain {
	font-family: AvenirNextLTPro !important;
}

.address-card:hover {
	box-shadow: 0px 0px 10px #aaaaaa92;
}

.address-card-name {
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 5px;
	font-family: AvenirNextLTPro;
	color: #ffffff;
}

.address-card-address-details {
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 5px;
	font-family: AvenirNextLTPro;
	color: #ffffff;
}

.address-card-edit-delete-container {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 10px 25px;
}

.address-card-edit-delete-button {
	color: #00ffff;
	font-size: 14px;
	font-weight: 600;
	font-family: AvenirNextLTPro;
	cursor: pointer;
}

@media only screen and (max-width: 480px) {
	.profile-page-select {
		margin-bottom: 20px;
	}

	.button-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
	}
	.order-item-title {
		font-size: 13px;
	}
	.layout-section {
		padding: 5px 30px;
	}

	.order-layout {
		padding: 5px 0;
	}

	.title-text {
		font-size: 22px !important;
		padding: 0;
	}

	.order-layout .title-text {
		font-size: 22px !important;
		padding: 0 20px;
	}

	.order-card {
		background-color: #f8f9fc;
	}

	.order-card .ant-card-body {
		padding: 10px 15px;
	}

	.title-help-header {
		color: #ffffff;
		font-weight: 600;
		font-size: 13px;
		font-family: AvenirNextLTPro;
	}

	.title-help-text {
		font-size: 12px;
	}

	.basic-btn {
		width: fit-content;
		font-size: 11px;
		height: 25px;
	}

	.order-item-collapse .ant-collapse-content-box {
		padding: 10px !important;
	}

	.mb-basic-btn {
		width: 95px;
	}

	.order-btn-container {
		margin: 0 10px;
	}
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
	.profile-page-select {
		margin-bottom: 20px;
	}

	.layout-section {
		padding: 5px 0;
	}

	.title-text {
		font-size: 22px !important;
		padding: 0 20px;
	}

	.order-card {
		background-color: #000000;
	}

	.order-card .ant-card-body {
		padding: 10px 15px;
	}

	.title-help-header {
		color: #848397;
		font-weight: 600;
		font-size: 13px;
		font-family: AvenirNextLTPro;
	}

	.title-help-text {
		font-size: 12px;
	}

	.basic-btn {
		width: fit-content;
		font-size: 11px;
		height: 25px;
	}

	.order-item-collapse .ant-collapse-content-box {
		padding: 10px !important;
	}

	.mb-basic-btn {
		width: 95px;
	}

	.order-btn-container {
		margin: 0 10px;
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.layout-section {
		padding: 10px 20px;
	}

	.title-text {
		font-size: 24px !important;
		padding: 0;
	}

	.order-card {
		background-color: #000000;
	}

	.title-help-header {
		color: #e8e8e8;
		font-weight: 600;
		font-size: 16px;
		font-family: AvenirNextLTPro;
	}

	.title-help-text {
		font-size: 14px;
	}

	.order-btn-container {
		margin: 0 20px;
	}

	.basic-btn {
		width: fit-content;
		font-size: 14px;
	}
}
