.product-detail-back-container {
	margin-left: 20px;
	margin-top: 20px;
	box-sizing: border-box;
	cursor: pointer;
	webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.product-detail-back-btn {
	margin-left: 30px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
	color: #fff;
}

.img-view-container {
	max-height: 500px;
}

.color-circle {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 10px;
	border: 2px solid #ddd;
	transition: all 0.3s ease;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.color-circle.selected {
	border-color: #1890ff;
	box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
}

.product-detail-back-btn-icon {
	font-size: 16px;

	margin-right: 5px;
}

.back-btn-text {
	background: #00ffff;
	border: 1px solid #f2f2f2;
	color: #000000;
	font-size: 14px;
	font-weight: bold;
	height: 30px;
	border: 1px solid #f7f7f7;
	font-family: AvenirNextLTPro;

	font-weight: 600;
	padding: 10px 20px;
	border-radius: 7px;
	width: 70%;
	text-align: center;
}
.back-btn-text:hover {
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);
	border: 1px solid #04b2a9 !important;
}

.add-to-compare:hover {
	color: #00ffff;
}
.row-with-margin {
	margin: 0 16px;
}
.btn-zone2 {
	display: flex;
	align-items: center;
}
.product-image-carousel .left-div-border,
.book-demo-form-container .left-div-border {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.product-image-carousel .image-alignment,
.book-demo-form-container .image-alignment {
	display: flex !important;
	justify-content: center !important;
	width: 140%;
}

.left-arrow-alignment,
.right-arrow-alignment {
	display: flex !important;
	width: 100%;
	color: #04b2a9;
}

.left-arrow-alignment {
	justify-content: end;
}

.right-arrow-alignment {
	justify-content: start;
}

.heading-style {
	font-size: 2rem;
	font-weight: 600;
	padding-top: 7%;
	font-family: AvenirNextLTPro;
	color: #fff;
}

.location-selector {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.product-img {
	width: 100%;
	object-fit: contain;
	height: auto;
}

.feature-icon-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 25px 0 0;
	align-items: center;
}

.feature-icon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 80px;
	width: 80px;
	border-radius: 5%;
}

.ant-spin-dot-item {
	background-color: #04b2a9 !important;
}

.feature-icon-img {
	width: 30px;
	height: 30px;
}

.heading-desc {
	font-weight: 500;
	margin-right: 35%;
	margin-top: 3%;
	color: #fff;
}

.cost {
	font-size: 30px;
	color: #00ffff !important;
	font-weight: 600;
	padding-top: 5%;
	font-family: AvenirNextLTPro;
}

.actual-cost {
	font-size: 22px;
	margin-left: 15px;
	color: #ffffff;
	font-weight: 600;
	font-family: AvenirNextLTPro;
}

.add-to-compare {
	text-decoration: 'underline';
	cursor: 'pointer';
	font-family: 'AvenirNextLTPro';
	color: #ffffff;
	font-size: 20px;
	font-weight: 600;
	transition: all 2s;
}

.square-icon {
	height: 20px;
	width: 20px;
	border: 1px solid #04b2a9;
	margin-top: 3px;
}

.discount-value {
	width: 90px;
	height: 32px;
	background-color: #00ffff;
	border-radius: 25px;
	font-family: AvenirNextLTPro;
	color: #000000;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
}

.product-specification-value {
	font-size: 20px;
	font-weight: 600;
	color: #ffffff;
	font-family: AvenirNextLTPro;
}

.product-specification-text {
	font-size: 14px;
	font-weight: 400;
	color: #848397;
	font-family: AvenirNextLTPro;
}

.location-header {
	font-family: 'AvenirNextLTPro';
	margin: 0 0 0 6px;
	font-weight: 600;
	font-size: 16px;
	position: relative;
	top: 2px;
	color: #fff;
}

.selected-location {
	color: #ff902d;
	font-weight: 600;
	font-size: 16px;
	font-family: AvenirNextLTPro;
	margin-top: 20px;
}

.custom-button {
	width: 200px;
	height: 50px;
	background-color: white;
	font-size: 20px;
	color: #04b2a9;
}

.custom-button2 {
	width: 200px;
	height: 50px;
	background-color: #04b2a9;
	font-size: 20px;
	color: white;
}

.btn-zone1 .btn-zone2 {
	margin-top: 60px;
}

.custom-button2 {
	margin-top: 10px;
}

.custom-button2:hover {
	color: white !important;
	background-color: #049089;
}

.feature-icon-text {
	text-align: center;
	font-size: 11px;
	padding: 2px;
}

.pincode-input::-webkit-outer-spin-button,
.pincode-input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	margin: 0;
}

.product-info-tooltip {
	height: 15px;
	padding-top: 8%;
}

.product-info-container {
	margin-top: 8%;
	margin-left: 18.5rem;
	background-color: #049089;
	color: #fff;
	border-radius: 5px;
	padding: 10px;
	position: absolute;
	z-index: 100;
}

.custom-tabs-container {
	padding: 10px 50px;
}

.product-info-text {
	color: #fff;
	font-family: AvenirNextLTPro;
	font-size: 12px;
}

.book-demo-btn {
	height: 40px;
	width: 150px;
	font-size: 14px;
}

.ant-spin-dot-item-custom .ant-spin-dot-item {
	background-color: #fff !important;
}

.shadow-md {
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.product-desc {
	font-size: 18px;
	width: 75%;
	color: #080305;
	padding: 5px 0 0 10px;
	font-family: AvenirNextLTPro;
}

.add-to-cart-btn {
	color: #000000;
	border: 1px solid #04b2a9;
	border-radius: 6px;
	height: 45px;
	width: 200px;
	font-weight: 600;
	font-family: AvenirNextLTPro;
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);
}

.buy-now-btn {
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);

	border: 1px solid #04b2a9;
	border-radius: 6px;
	height: 45px;
	width: 200px;
	color: #000000;
	font-weight: 600;
	font-family: AvenirNextLTPro;
}

.buy-now-btn:hover {
	color: #000000 !important;
	border: 1px solid #04b2a9 !important;
	box-shadow: 0px 0px 10px #aaaaaa92;
}

.add-to-cart-btn:hover {
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);
	border: 1px solid #04b2a9 !important;
	box-shadow: 0px 0px 10px #aaaaaa92;
	color: #000000 !important;
}

.product-details-header {
	display: flex;
	justify-content: center;
	align-items: center;
}

.product-details-header .image-caroursel-content {
	height: 300px;
	width: 75%;
	border: 1px solid #e3e3e3;
}

.caroursel-image-alignment {
	display: flex;
	justify-content: center;
	align-items: center;
}

.custom-tab-links .ant-tabs-tab {
	color: #fff;
	font-weight: 600;
	font-size: 18px;
	font-family: AvenirNextLTPro;
}

.custom-tab-links .ant-tabs-tab:hover {
	color: #00ffff;
}

.custom-tab-links .ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #00ffff !important;
	font-weight: 600;
}

.custom-tab-links .ant-tabs-ink-bar {
	position: absolute;
	background: #00ffff;
	pointer-events: none;
}

@media only screen and (min-width: 1200px) {
	/* Styles for large screens */
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.product-detail-back-container {
		position: relative;
		top: 1rem;
		margin: 20px 0 30px 30px;
	}

	.product-detail-back-btn {
		margin-left: 20px;
	}

	.product-detail-back-btn-icon {
		font-size: 16px;
		color: #7f7f8b;
		position: relative;
		top: 1.3px;
	}

	.back-btn-text {
		font-family: AvenirNextLTPro;
		margin-top: -2px;
		color: #7f7f8b;
		margin-left: 5px;
	}

	.product-image-carousel {
		padding: 20px 0 !important;
		margin-top: 10px;
	}

	.product-detail-container {
		padding: 0 50px !important;
	}

	.heading-style {
		font-size: 2rem !important;
	}

	.product-info-container {
		margin-top: 7%;
		margin-left: 27.5rem !important;
		padding: 10px;
	}

	.product-info-text {
		color: #fff;
		font-family: AvenirNextLTPro;
		font-size: 12px !important;
	}

	.custom-quote-container {
		margin: 0 12px 10px 12px;
	}

	.product-specification-container {
		text-align: center;
	}

	.product-specification-value {
		font-size: 20px;
	}

	.product-specification-text {
		font-size: 14px;
		font-weight: 400;
		color: #848397;
		font-family: AvenirNextLTPro;
	}

	.location-header {
		font-size: 14px;
	}

	.cost {
		font-size: 26px;
	}

	.actual-cost {
		font-size: 20px;
		margin-left: 10px;
	}

	.discount-value {
		width: 75px;
		height: 30px;
		font-size: 13px !important;
	}

	.pincode-input {
		width: 150px;
	}

	.edit-icon-active {
		margin-left: 0;
	}

	.edit-icon {
		margin-left: 0.5rem;
	}

	.btn-zone1 {
		margin: 10px 0;
	}

	.btn-zone2 {
		padding-top: 20px;
		margin: 40px 0;
	}

	.add-to-cart-btn {
		margin: 0 10px 0 10px !important;
	}
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
	.product-detail-back-container {
		position: relative;
		top: 1rem;
		margin: 20px 0 30px 30px;
	}

	.product-detail-back-btn {
		margin-left: 20px;
	}

	.product-detail-back-btn-icon {
		font-size: 16px;
		color: #7f7f8b;
		position: relative;
		top: 1.3px;
	}

	.back-btn-text {
		font-family: AvenirNextLTPro;
		margin-top: -2px;
		color: #7f7f8b;
		margin-left: 5px;
	}

	/* Styles for mobile devices */
	.heading-style {
		font-size: 2rem;
		padding-top: 10%;
	}

	.product-img {
		padding-top: 40px;
	}

	.feature-icon {
		height: 60px;
		width: 60px;
	}

	.feature-icon-img {
		width: 20px;
		height: 20px;
	}

	.feature-icon-wrapper {
		margin: 0 10px 0 0;
	}

	.btn-zone1 {
		margin: 10px 0;
	}

	.btn-zone2 {
		margin-top: 0px;
	}

	.custom-button2 {
		margin-bottom: 10px;
	}
}

@media only screen and (max-width: 480px) {
	.product-detail-back-container {
		margin: 20px 0px;
	}

	.img-view-container {
		max-height: 270px;
	}

	.addToBtn {
		margin-top: 10px;
		justify-content: center !important;
	}

	.product-detail-back-btn {
		cursor: pointer;
		margin: 10px 0px;
		margin-left: 20px;
	}

	.product-detail-back-btn-icon {
		font-size: 16px;
		color: #7f7f8b;
	}

	.back-btn-text {
		font-family: AvenirNextLTPro;
		margin-top: -2px;
		color: #7f7f8b;
		margin-left: 5px;
	}

	.heading-style {
		font-size: 1.8rem !important;
		padding-top: 10% !important;
	}

	.product-info-container {
		margin-top: 8%;
		margin-left: 16.5rem !important;
		padding: 10px;
	}

	.product-info-text {
		color: #fff;
		font-family: AvenirNextLTPro;
		font-size: 10px !important;
	}

	.custom-quote-container {
		margin: 0 12px 10px 12px;
	}

	.product-specification-container {
		text-align: center;
	}

	.product-specification-value {
		font-size: 16px;
	}

	.product-specification-text {
		font-size: 11px;
		font-weight: 400;
		color: #848397;
		font-family: AvenirNextLTPro;
	}

	.location-header {
		font-size: 14px;
	}

	.cost {
		font-size: 26px;
	}

	.actual-cost {
		font-size: 20px;
		margin-left: 10px;
	}

	.discount-value {
		width: 75px;
		height: 30px;
		font-size: 13px !important;
	}

	.pincode-input {
		width: 150px;
	}

	.edit-icon-active {
		margin-left: 4.5rem;
	}

	.edit-icon {
		margin-left: 0.5rem;
		color: #fff;
	}

	.btn-zone1 {
		margin: 10px 0;
	}

	.btn-zone2 {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		margin: 25px 0;
	}

	.add-to-cart-btn {
		margin: 0 10px 0 10px !important;
		width: 90%;
	}
	.buy-now-btn {
		width: 90%;
	}
	.custom-tabs-container {
		padding: 10px 20px !important;
	}

	.custom-tab-links .ant-tabs-tab {
		font-size: 16px;
	}

	.custom-button2 {
		margin-bottom: 10px;
	}

	.feature-icon-wrapper {
		margin: 0 10px 0 0;
	}

	.feature-icon {
		height: 60px;
		width: 60px;
	}

	.feature-icon-wrapper:nth-child(3) {
		margin-top: 15px;
	}

	.feature-icon-wrapper:nth-child(4) {
		margin-right: 0;
	}

	.feature-name {
		font-size: 12px;
	}

	.feature-icon-img {
		width: 20px;
		height: 20px;
	}

	.product-img {
		padding-top: 40px;
		width: 170px;
		height: auto;
	}

	.product-image-carousel .image-alignment,
	.book-demo-form-container .image-alignment {
		margin-left: 20px;
	}

	.book-demo-title {
		padding: 0 15px;
	}

	.book-demo-form-container {
		padding: 0 5px;
	}

	.book-demo-product-image {
		width: 90% !important;
	}

	.book-demo-product-specification {
		text-align: center;
	}

	.book-demo-form-content {
		padding: 0 20px;
	}
}

@media only screen and (min-width: 400px) and (max-width: 480px) {
	/* Styles for small screens */
	.feature-icon-wrapper:nth-child(3) {
		margin-top: 0;
	}
}
