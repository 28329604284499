.about-third-section-container {
	background-color: #000000;
	margin: 40px 0;
}

.vector11 {
	margin-top: -35.5px;
	width: 440px !important;
}

.about-third-right-container {
	height: 100%;
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	padding: 10px 0;
}

.about-third-title {
	font-size: 47px;
	font-weight: 700;
	color: white;
	font-family: AvenirNextLTPro;
	margin-bottom: 20px;
	line-height: 40px;
}

.about-third-description {
	color: #fff;
	line-height: 27px;
	font-family: AvenirNextLTPro;
	font-size: 18px !important;
	text-align: justify;
	hyphens: auto;
	line-break: auto;
}

@media screen and (max-width: 480px) {
	.about-third-left-container {
		display: none;
	}
	.about-third-title {
		font-size: 36px;
		padding: 10px 0px;
	}
	.about-third-section-container {
		margin: 0;
		height: fit-content;
	}
	.about-third-description {
		word-spacing: -1px;
	}
	.about-third-right-container {
		height: fit-content;
		text-align: center;
		padding: 10% 10%;
	}
}

@media screen and (min-width: 481px) and (max-width: 767px) {
	.about-third-left-container {
		display: none;
	}
	.about-third-title {
		font-size: 36px;
		padding: 25px 0px;
	}
	.about-third-section-container {
		margin: 0;
		height: fit-content;
	}

	.about-third-right-container {
		height: fit-content;
		text-align: center;
		padding: 10% 10%;
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.about-third-section-container {
		margin: 6rem 0 !important;
		height: 60vh !important;
	}

	.about-third-title {
		font-size: 34px;
		margin: 20px 0;
	}
}

@media screen and (min-width: 576px) {
	.about-third-left-container {
		display: flex;
		justify-content: end;
	}

	.about-third-section-container {
		margin-top: 0%;
		height: 40vh;
	}

	.about-third-right-container {
		text-align: left;
		padding: 0% 4%;
	}
}

@media screen and (min-width: 860px) {
	.navbar-menu {
		height: 11vh;
	}

	.about-third-section-container {
		height: 85vh;
	}

	.about-third-right-container {
		padding: 0% 5%;
	}
}
