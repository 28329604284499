.navbar-menu {
	padding: 0% 5%;
	background-color: #1d1e18;
	color: #fff;
	align-items: center;
	justify-content: end;
	gap: 20px;
}

.navbar {
	padding: 20px;
	background-color: #1d1e18;
}

.anticon-close svg {
	top: 0px !important;
}

.space-container {
	background-color: #1d1e18 !important;
	color: #fff;
}
.contact-info-sub-text,
.contact-first-title {
	color: #fff !important;
}

.navbar .ant-menu-horizontal {
	border-bottom: none;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
	border-bottom-color: #00ffff !important;
}

.ant-menu-item-active::after {
	border-bottom-color: #00ffff !important;
}
.space-text {
	color: #fff !important;
}

.ant-dropdown-menu-title-content {
	font-family: AvenirNextLTPro;
}

.nav-logo-container {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

/* ant-menu-title-content */
.nav-item {
	font-weight: 400;
	font-family: AvenirNextLTPro;
	color: #fff;
	font-size: 15px;
}

/* .nav-item:hover {
    border-bottom: 1.5px solid black;
} */

/* movile nvbar */
.mobile-navbar {
	height: 50px;
	padding: 0% 2%;
}

.space-container {
	width: 100%;
	background-color: white;
	padding: 2% 0%;
}

.space-item {
	padding: 2% 5%;
	background-color: rgb(0, 0, 0);
}

.space-text {
	font-weight: 500;
	letter-spacing: 0.4px;
}

@media screen and (min-width: 200px) {
	.navbar-menu {
		height: 8vh;
	}

	.nav-logo {
		margin-left: 20%;
		width: 100px !important;
	}
}

@media screen and (min-width: 576px) {
	.navbar-menu {
		height: 5vh;
	}

	.nav-logo {
		width: 120px !important;
		margin-left: 0%;
	}
}

@media screen and (min-width: 860px) {
	.navbar-menu {
		height: 20vh;
	}

	.nav-logo {
		width: 80% !important;
		object-fit: contain;
		object-position: center;
	}
}
