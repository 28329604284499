/* container */
.product-offer-banner {
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);
	color: black;
}

/* left container */
.product-offer-banner-left {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-offer-banner-middle {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-offer-banner-end {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.product-offer-banner-content {
	gap: 25px;
	display: flex;
	justify-content: start;
	flex-direction: column;
}

.product-offer-banner-title {
	color: rgb(0, 0, 0);
	font-size: 26px !important;
	font-weight: 600;
	font-family: AvenirNextLTPro;
	letter-spacing: 1px;
}

.product-offer-banner-description {
	color: rgb(0, 0, 0);
	font-size: 15px;
	line-height: 28px;
	font-weight: 300;
	font-family: AvenirNextLTPro;
}

.product-offer-banner-explore-btn {
	background: transparent;
	color: rgb(0, 0, 0);
	font-size: 13px;
	height: 40px;
	border: 1px solid white;
}

.product-offer-banner-explore-btn:hover {
	background-color: white;
	border-color: #04b2a9 !important;
	color: #04b2a9 !important;
}

@media screen and (max-width: 480px) {
	.product-offer-banner-left {
		padding: 0% 20px 0% 20px;
	}

	.product-offer-banner-title {
		font-size: 18px !important;
		text-align: center;
	}
}

@media screen and (min-width: 481px) and (max-width: 767px) {
	.product-offer-banner-left {
		padding: 0% 25px 0% 25px;
	}

	.product-offer-banner-title {
		font-size: 24px !important;
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.product-offer-banner-left {
		padding: 0% 25px 0% 25px;
	}
}

@media screen and (min-width: 200px) {
	.product-offer-banner {
		height: 100vh;
	}

	.product-offer-banner-middle {
		padding: 15px;
	}

	.product-offer-banner-right {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.product-offer-banner-explore-btn {
		width: 50%;
	}
}

@media screen and (min-width: 576px) {
	.product-offer-banner {
		height: 45vh;
	}

	.product-offer-banner-middle {
		padding: 15px;
	}

	.product-offer-banner-explore-btn {
		width: 50%;
	}
}

@media screen and (min-width: 860px) {
	.product-offer-banner {
		height: 89vh;
	}

	.product-offer-banner-explore-btn {
		width: 40%;
	}

	.product-offer-banner-left {
		padding: 0% 0% 0% 4%;
	}
}
