.first {
	margin: 30px 35px;
}

.title {
	font-size: 47px;
	font-weight: 600;
	font-family: AvenirNextLTPro;
}

.bold-text {
	font-size: 16px;
	font-weight: 600;
	font-family: AvenirNextLTPro;
}

.continue {
	text-decoration: underline;
	color: #00ffff;
	font-weight: bold;
	font-family: AvenirNextLTPro;
	font-size: 18px;
}

.bg-black {
	background-color: black;
}

.card-alignment {
	margin: 0 20px;
	border-radius: 0;
}

.card-alignment .ant-card-body {
	padding: 25px 0 0 0;
}

.product-image-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.product-image {
	/* height: 100%; */
	width: inherit;
}

.product-title {
	font-size: 26px;
	font-weight: 600;
	font-family: AvenirNextLTPro;
	color: #ffffff;
}

.product-price {
	font-size: 18px;
	font-weight: 600;
	font-family: AvenirNextLTPro;
	color: #fff;
}

.product-details-container {
	margin: 10px 0;
}

.product-details {
	color: #fff;
	font-family: AvenirNextLTPro;
	padding: 5px 0;
}

.quantity-container-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.quantity-container {
	background-color: #f8f9fc;
	border: 1px solid #e3e3e3;
	width: 85px;
	height: 30px;
}

.increment-decrement-btn {
	padding: 0 15px;
	font-weight: 600;
	font-size: 18px;
	cursor: pointer;
}

/* .increment-btn, .decrement-btn {
  border-radius: 0;
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-size: 18px;
}

.decrement-btn {
  margin-top: -5px;
}

.increment-btn {
  margin-top: -4px;
}

*/

.item-count {
	font-family: AvenirNextLTPro;
	font-size: 16px;
	font-weight: 600;
	margin-top: 5px;
}

.delete-btn {
	width: 22px;
	height: 22px;
	margin: 0 15px;
	cursor: pointer;
}

.checkout-container {
	margin: 40px 0 20px 0;
}

.checkout-btn {
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);
	color: #000000;
	font-size: 16px;
	height: 50px;
	border: 1px solid #f7f7f7;
	font-family: AvenirNextLTPro;
	width: 170px;
	font-weight: 600;
	margin: 20px 0;
}

.pt {
	padding-top: 20px;
}
.pt-40 {
	padding-top: 40px;
}
.pt-70 {
	padding-top: 70px;
}
.pr-30 {
	padding-right: 30px;
}
.pr-20 {
	padding-right: 20px;
}
.bold-text {
	font-weight: bold;
}
.pb-40 {
	padding-bottom: 50px;
}
.bottom-border {
	border-bottom: 1px solid gray;
	padding-bottom: 10px;
}
.checkout {
	background-color: #00ffff;
	border: none;
	padding: 25px;
	margin: 10px auto;
	width: 100%;
	color: rgb(0, 0, 0);
	display: flex;
	align-items: center;
	justify-content: center;
}
.checkout:hover {
	color: rgb(0, 0, 0) !important;
}
.display-right {
	display: flex;
	justify-content: flex-end;
}
.pl-30 {
	padding-left: 30px;
}

.responsive-cart-container {
	width: 100%;
}

.responsive-cart-container-header {
	border: 1px solid #e3e3e3;
	border-radius: 20px;
	padding: 10px 15px;
	background-color: #1d1e18;
	margin: 15px 0;
}

.responsive-cart-container-header .responsive-cart-image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.responsive-cart-container-header
	.responsive-cart-image-container
	.responsive-cart-image {
	width: 100%;
}

.responsive-cart-container-header .responsive-cart-item-name {
	font-family: AvenirNextLTPro;
	font-size: 20px;
	font-weight: 600;
	color: #ffffff;
}

.responsive-cart-container-header .responsive-cart-item-price {
	font-family: AvenirNextLTPro;
	font-size: 16px;
	font-weight: 600;
	color: #fff;
}

.responsive-quantity-container {
	display: flex;
	align-items: center;
	margin: 20px 0;
}

.responsive-quantity-container .responsive-quantity-container-wrapper {
	background-color: #fff;
	border: 1px solid #e3e3e3;
	border-radius: 20px;
	padding: 3px 5px;
}

.responsive-quantity-container
	.responsive-quantity-container-wrapper
	.increment-decrement-btn {
	padding: 0 15px;
	font-weight: 600;
	font-size: 16px;
	cursor: pointer;
}

.responsive-quantity-container
	.responsive-quantity-container-wrapper
	.item-count {
	font-family: AvenirNextLTPro;
	font-size: 14px;
	font-weight: 600;
	margin-top: 5px;
	color: #fff;
}

.responsive-cart-checkout-container {
	border: 1px solid #04b2a9;
	border-radius: 20px;
	padding: 15px;
	background-color: #000000;
}

.responsive-cart-selected-count {
	font-size: 14px;
	font-family: AvenirNextLTPro;
	color: #fff;
}

.responsive-cart-subtotal {
	font-size: 20px;
	font-weight: 600;
	font-family: AvenirNextLTPro;
	color: #fff;
}

.responsive-cart-checkout-help-text {
	font-size: 14px;
	font-family: AvenirNextLTPro;
	color: #fff;
}

anticon svg {
	color: #000000 !important;
}
