.about-first-section-container {
	/* background-image: url("./../../../../public/images/Top-Electric-Bikes.jpg"); */
	background-image: url('./../../../../public/images/about-hero.webp');
	background-size: cover;
	background-repeat: no-repeat;
}

.about-first-sub-container {
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	align-items: center;
	gap: 10px;
}

.about-first-title {
	color: white;
	font-size: 47px;
	font-weight: 700;
	font-family: AvenirNextLTPro;
}

.about-first-description {
	color: white;
	letter-spacing: 0.4px;
	font-weight: 800;
	font-size: 20px;
	font-family: AvenirNextLTPro;
	text-align: justify;
}

.glowing-text {
	/* text-shadow: 0 0 10px rgb(60, 176, 34), 0 0 20px rgb(32, 164, 17), 0 0 30px rgb(21, 195, 53), 0 0 40px rgb(6, 163, 40), 0 0 50px rgb(12, 163, 57), 0 0 60px rgb(15, 166, 51), 0 0 70px rgb(14, 165, 67); */
	animation: aniload 3s;
}

.glowing-text::after {
	content: 'Lilypad';
	display: flex;
	transform: rotateX(180deg);
	background-image: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0) 10%,
		rgba(255, 255, 255, 0.5)
	);
	-webkit-background-clip: text;
	color: transparent;
}

@keyframes aniload {
	from {
		transform: translate(-1000px, 0px);
	}

	to {
		transform: translate(0px, 0px);
	}
}

@media screen and (max-width: 768px) {
	.about-first-sub-container {
		text-align: center;
	}

	.about-first-description {
		font-size: 16px;
		padding: 10px;
	}
	.about-founder-container {
		height: fit-content !important;
	}

	.about-first-title {
		line-height: 50px;
	}

	.about-founder-title {
		font-size: 24px;
	}

	.about-first-section-container {
		height: 30vh;
	}
}

@media screen and (min-width: 576px) {
	.about-first-section-container {
		height: 27vh;
	}

	.about-first-title {
		line-height: 100px;
	}
}

@media screen and (min-width: 860px) {
	.about-first-sub-container {
		text-align: center;
	}

	.about-first-title {
		line-height: 60px;
	}

	.about-first-section-container {
		height: 44vh;
	}
}
