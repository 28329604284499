.product-description-text {
	color: #ffffff;
	font-size: 16px;
	font-family: AvenirNextLTPro;
}

.product-description-secondary-text {
	color: #ffffff;
	font-size: 16px;
	font-family: AvenirNextLTPro;
}

.product-description-header {
	color: #ffffff;
	font-weight: 600;
	margin: 10px 0;
	font-size: 18px;
	font-family: AvenirNextLTPro;
}

.ant-collapse-header-text {
	font-size: 16px;
	font-weight: 600;
}

.ant-collapse-item-active {
	box-shadow: 0 3px 5px #ffffff;
}

.ant-collapse-item {
	border: 1px solid #f6f6f6 !important;
	border-radius: 5px;
}
.space-item {
	border: 1px solid #f6f6f6 !important;
	border-radius: 5px;
}
.ant-menu {
	background-color: #1d1e18 !important;
}
.accordion-icon {
	color: #ffffff !important;
	font-size: 16px !important;
	border-radius: 50%;
	border: 1px solid #f4f4f4;
	padding: 10px;
	box-shadow: 0 3px 10px #d9d9d9;
}

.accordion-icon-active {
	background-color: #00ffff !important;
	color: #ffffff !important;
	font-size: 16px !important;
	border-radius: 50%;
	border: 1px solid #f4f4f4;
	padding: 10px;
	box-shadow: 0 3px 10px #d9d9d9;
}

.overview-table {
	font-family: AvenirNextLTPro;
	border-collapse: collapse;
	width: 100%;
	font-size: 14px;
	color: #ffffff;
	margin-top: 10px;
}

.overview-table td,
th {
	text-align: left;
	padding: 8px;
}

.overview-table tr:nth-child(odd) {
	background-color: #1d1e18;
	color: #ffffff;
}

.overview-table td:first-child {
	width: 340px;
	padding-left: 20px;
}

.checked-icon {
	font-size: 16px;
	color: #52c41a;
}

.closed-icon {
	font-size: 16px;
	color: #ff4d4f;
}

.product-specifications {
	color: #ffffff;
	font-size: 15px;
	padding: 0 10px;
	font-family: AvenirNextLTPro;
}

.other-details-table {
	margin: 20px 0 50px 0;
}

.review-section {
	margin: 20px 0;
}

.review-section-container {
	width: 70px;
	height: 70px;
}

.star-display {
	height: 65px;
	width: 65px;
	background-color: #f5fffe;
	border-radius: 50%;
	color: #1c1800;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	/* padding: 5px; */
}

.user-name {
	font-family: AvenirNextLTPro;
	font-weight: 600;
	font-size: 16px;
	padding: 0 10px 0 20px;
	color: #ffffff;
	position: relative;
	top: 2px;
}

.review-date {
	font-family: AvenirNextLTPro;
	font-weight: 400;
	font-size: 13px;
	color: #ffffff;
	padding: 0 10px;
}

.ant-pagination-item-link {
	background-color: #00ffff !important;
	color: #000000 !important;
}

.ant-pagination-item {
	color: #000000 !important;
}
.ant-table-cell {
	background-color: #000000 !important;
	color: #fff !important;
}

.no-review-text {
	font-family: AvenirNextLTPro;
	font-weight: 600;
	font-size: 20px;
	color: #ffffff;
	text-align: center;
}

.review-text {
	font-family: AvenirNextLTPro;
	font-weight: 400;
	font-size: 14px;
	color: #ffffff;
}

.reviews-table .ant-table-thead {
	display: none;
}

.reviews-table .ant-table-cell {
	padding: 0;
}

@media only screen and (max-width: 480px) {
	.product-description-text {
		font-size: 14px !important;
	}

	.accordion-icon {
		font-size: 14px !important;
		padding: 7px;
	}

	.accordion-icon-active {
		font-size: 14px !important;
		padding: 7px;
	}

	.overview-table td:first-child {
		width: 200px !important;
		padding-left: 5px;
	}

	.other-details-table {
		margin: 10px 0 25px 0;
	}

	.product-specifications {
		width: 100%;
		font-size: 14px;
	}

	.review-section-container {
		width: 55px;
		height: 55px;
	}

	.reviews-table .ant-table-cell {
		padding: 0 !important;
	}

	.star-display {
		height: 55px;
		width: 55px;
		font-size: 20px;
	}
}
