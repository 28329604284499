.select-address-card-container {
	padding: 0 40px;
	margin: 30px 0;
	border-right: 1px solid #e3e3e3;
}

.payment-section-container {
	padding: 0 25px;
	margin-top: 30px;
	border-right: 1px solid #e3e3e3;
}

.address-card {
	margin: 10px 0;
}

.outline-btn {
	background: transparent;
	color: #04b2a9 !important;
	font-size: 14px;
	height: 45px;
	width: 180px;
	border: 1px solid #04b2a9 !important;
	font-family: AvenirNextLTPro;
	margin: 10px 15px 10px 0;
	border-radius: 9px;
}

.outline-btn:hover {
	background-color: white;
	border-color: #04b2a9 !important;
	color: #04b2a9 !important;
	box-shadow: 0px 0px 10px #aaaaaa92;
	font-weight: 600;
}

.gradient-btn {
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);
	color: #000000;
	font-size: 14px;
	height: 45px;
	border: 1px solid #f7f7f7;
	font-family: AvenirNextLTPro;
	width: 180px;
	font-weight: 600;
	border-radius: 9px;
}

.gradient-btn:hover {
	border: 1px solid #04b2a9 !important;
}

/* .payment-radio .ant-radio-checked .ant-radio-inner {
    background-color: #04B2A9 !important;
} */

.payment-radio .ant-radio-checked .ant-radio-inner {
	background-color: #fff !important;
	border-color: #04b2a9 !important ;
}

.payment-radio .ant-radio-checked .ant-radio-inner:after {
	background-color: #04b2a9 !important;
}

.payment-radio .ant-radio:hover .ant-radio-inner {
	border-color: #04b2a9 !important ;
}

.address-form-inputs {
	border: 1px solid #e3e3e3;
	border-radius: 9px;
	padding: 15px;
	font-family: AvenirNextLTPro;
	font-size: 14px;
}

.address-form-select .ant-select-selector {
	position: relative;
	background-color: #ffffff;
	border: 1px solid #e3e3e3;
	border-radius: 9px;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	height: 53px !important;
	font-family: AvenirNextLTPro !important;
}

.address-form-select-arrow {
	margin-top: 15px !important;
}

.address-form-select .ant-select-open,
.select-option,
.ant-select-item-option-content {
	font-family: AvenirNextLTPro !important;
	color: #848397 !important;
}

.order-success-container {
	padding: 25px 0;
	width: 100%;
	text-align: center;
}

.order-success-help-container {
	padding: 0 40px;
	width: 75%;
	margin: 0 auto;
}

.outer-circle {
	margin-top: 20px;
	border-radius: 50%;
	height: 150px;
	width: 150px;
	background-color: #f3f5ff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.inner-circle {
	border-radius: 50%;
	height: 100px;
	width: 100px;
	background-color: #f3f5ff;
	border: 3px solid #04b2a9;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px;
}

.order-success-header {
	font-family: AvenirNextLTPro;
	font-size: 40px;
	font-weight: 600;
	color: #04b2a9;
}

.order-success-help-text {
	font-family: AvenirNextLTPro;
	font-size: 20px;
	color: #848397;
}

.continue-to-shipping {
	width: 220px;
}

.shipping-section {
	padding: 0 30px;
	margin-top: 30px;
	border-right: 1px solid #ccc;
}

.order-success-btn-container {
	padding: 20px 0;
}

.order-success-btn-container .outline-btn,
.order-success-btn-container .gradient-btn {
	width: 200px;
}

@media only screen and (max-width: 480px) {
	.select-address-card-container {
		padding: 0 20px;
		margin: 10px 0;
		border-right: none;
	}

	.add-address-btn {
		height: 45px;
		width: 160px;
		margin: 0 5px;
	}

	.select-address-btn {
		height: 45px;
		width: 160px;
		margin: 0 5px;
	}

	.address-btns-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.continue-to-shipping {
		width: 100%;
		height: 55px;
	}

	.shipping-section {
		padding: 0 20px;
		border-right: none;
	}

	.checkout-card-text,
	.checkout-card-total {
		font-size: 14px;
		font-weight: 600;
		font-family: AvenirNextLTPro;
	}

	.checkout-card-total {
		font-size: 16px;
	}

	.payment-btn-container {
		width: 100%;
		padding: 20px 0;
	}

	.payment-btn-container .gradient-btn {
		width: 100%;
		height: 55px;
	}

	.order-success-header {
		font-size: 32px;
	}

	.order-success-help-container {
		padding: 0;
		width: 80%;
	}

	.order-success-help-text {
		font-size: 16px;
	}

	.order-success-btn-container {
		display: flex;
		padding: 0 20px;
		margin: 10px 0;
		justify-content: center;
		align-items: center;
	}

	.close-btn {
		margin-left: 0px !important;
	}
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
	.select-address-card-container {
		padding: 0 20px;
		margin: 10px 0;
		border-right: none;
	}

	.add-address-btn {
		height: 45px;
		width: 160px;
		margin: 0 5px;
	}

	.select-address-btn {
		height: 45px;
		width: 160px;
		margin: 0 5px;
	}

	.address-btns-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.continue-to-shipping {
		width: 100%;
		height: 55px;
	}

	.shipping-section {
		padding: 0 20px;
		border-right: none;
	}

	.checkout-card-text,
	.checkout-card-total {
		font-size: 14px;
		font-weight: 600;
		font-family: AvenirNextLTPro;
	}

	.checkout-card-total {
		font-size: 16px;
	}

	.payment-btn-container {
		width: 100%;
		padding: 20px 0;
	}

	.payment-btn-container .gradient-btn {
		width: 100%;
		height: 55px;
	}

	.order-success-header {
		font-size: 32px;
	}

	.order-success-help-container {
		padding: 0;
		width: 80%;
	}

	.order-success-help-text {
		font-size: 16px;
	}

	.order-success-btn-container {
		display: flex;
		padding: 0 20px;
		margin: 10px 0;
		justify-content: center;
		align-items: center;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.select-address-card-container {
		padding: 10px 30px 10px 20px;
		margin: 10px 20px 10px 0;
	}

	.payment-section-container {
		padding: 0 10px 0 0;
		margin-top: 30px;
	}

	.payment-page-breadcrumb {
		font-size: 16px !important;
	}

	.add-address-btn {
		height: 45px;
		width: 140px;
		padding: 0 10px;
	}

	.select-address-btn {
		height: 45px;
		width: 160px;
		margin: 0 5px;
	}

	.address-btns-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-top: 50px;
	}

	.continue-to-shipping {
		width: 100%;
		height: 55px;
	}

	.shipping-section {
		padding: 0 20px;
		border-right: none;
	}

	.checkout-card-text,
	.checkout-card-total {
		font-size: 14px;
		font-weight: 600;
		font-family: AvenirNextLTPro;
	}

	.checkout-card-total {
		font-size: 16px;
	}

	.payment-btn-container {
		width: 100%;
		padding: 20px 0;
	}

	.payment-btn-container .gradient-btn {
		width: 100%;
		height: 55px;
	}

	.order-success-header {
		font-size: 32px;
	}

	.order-success-help-container {
		padding: 0;
		width: 80%;
	}

	.order-success-help-text {
		font-size: 16px;
	}

	.order-success-btn-container {
		display: flex;
		padding: 0 20px;
		margin: 10px 0;
		justify-content: center;
		align-items: center;
	}
}
