.contact-first-section-container {
	padding: 5% 0%;
}

.contact-first-left-container {
	/* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
	/* gap: 30px; */
}
.contact-first-left-bottom-container {
	width: 100%;
	margin-top: 30px;
}
.contact-first-left-top-container {
	/* display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px; */
}

.contact-first-sub-title {
	font-size: 15px;
	font-weight: 500;
	letter-spacing: 1px;
	color: rgb(255, 255, 255);
	line-height: 10px;
	font-family: AvenirNextLTPro;
}

.contact-first-title {
	font-size: 47px;
	font-weight: 700;
	line-height: 50px;
	font-family: AvenirNextLTPro;
}

.contact-first-description {
	letter-spacing: 0.4px;
	/* width: 60%; */
	color: rgb(255, 255, 255);
	font-family: AvenirNextLTPro;
}

.contact-input {
	padding-left: 0px;
	border: none;
	border-radius: 0px;
	height: 44px;
	border-bottom: 1px solid rgb(210, 210, 210);
	margin-bottom: 4%;
	font-family: AvenirNextLTPro;
	padding: 10px;
}

.contact-input:focus {
	box-shadow: none !important;
	border-color: rgb(255, 255, 255) !important;
}

.contact-input::placeholder {
	color: rgb(0, 0, 0) !important;
}

.contact-btn {
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);
	display: flex;
	justify-content: center;
	align-items: center;
	color: rgb(0, 0, 0);
	height: 40px;
	width: 150px;
	font-family: AvenirNextLTPro;
}

.contact-btn:hover {
	border: 1.4px solid #04b2a9 !important;
}

/* right */
.contact-first-right-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contact-first-right-sub-container {
	display: flex;
	flex-direction: column;
	justify-content: start;
}

.contact-company-info-container {
	margin-top: 3%;
	gap: 10px;
	display: flex;
	flex-direction: column;
}

.contact-info-text {
	font-size: 24px;
	font-weight: 700;
	font-family: AvenirNextLTPro;
}

.contact-info-sub-text {
	font-weight: 500;
	font-family: AvenirNextLTPro;
}

.contact-icon {
	margin-right: 4%;
}

@media screen and (min-width: 200px) {
	.contact-first-title {
		font-size: 36px;
		font-weight: 700;
		line-height: 40px;
	}
	.contact-first-section-container {
		height: fit-content;
	}

	.contact-first-left-container {
		padding: 20% 6%;
	}

	.contact-first-right-container {
		padding: 8%;
	}
}

@media screen and (min-width: 576px) {
	/* .contact-first-section-container {
        height: 60vh;
    } */

	.contact-first-left-container {
		padding: 7% 6%;
	}

	.contact-first-right-container {
		padding: 0%;
	}
}

@media screen and (min-width: 860px) {
	/* .contact-first-section-container {
        height: 110vh;
    } */

	.contact-first-left-container {
		padding: 3% 6%;
	}
}
