.about-second-left-container {
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
.about-second-right-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	width: 100%;
}

.about-second-right-container .second-image {
	width: 400px;
}

.about-second-title {
	font-size: 47px;
	font-weight: 700;
	font-family: AvenirNextLTPro;
	line-height: 20px;
	color: #ffff;
}

.about-second-description {
	letter-spacing: 0.4px;
	line-height: 27px;
	color: #ffff;
	font-family: AvenirNextLTPro;
	font-size: 18px !important;
	text-align: justify;
	hyphens: auto;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.about-second-section-container {
		margin: 7rem 0;
	}
}

@media screen and (min-width: 481px) and (max-width: 767px) {
	.about-second-left-container {
		padding: 10%;
		text-align: center;
	}
	.about-second-title {
		font-size: 36px;
	}

	.about-second-right-container .second-image {
		width: 100%;
	}
}

@media screen and (max-width: 480px) {
	.about-second-left-container {
		padding: 10%;
		text-align: center;
	}
	.about-second-title {
		font-size: 36px;
	}
	.about-second-right-container .second-image {
		width: 100%;
	}
	.about-second-description {
		padding: 0px 7px;
	}
}

@media screen and (min-width: 576px) {
	.about-second-left-container {
		/* text-align: left; */
		padding: 0% 4%;
	}

	.about-second-section-container {
		height: 45vh;
	}
}

@media screen and (min-width: 860px) {
	.about-second-left-container {
		/* text-align: left; */
		padding: 0% 5%;
	}

	.about-second-section-container {
		height: 100vh;
		background-color: #000000;
		color: #ffff;
	}
}
