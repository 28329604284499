.about-top-container {
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	gap: 0px;
	background-color: #000000;
	color: #ffffff !important;
}

.about-fourth-title {
	font-size: 47px;
	font-weight: 700;
	line-height: 20px;
	color: #fff;
	font-family: AvenirNextLTPro;
}

.about-fourth-description {
	color: #fff;
	line-height: 27px;

	font-size: 18px !important;
	text-align: justify;
	hyphens: auto;
	line-break: auto;
}

.about-founder-container {
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);

	/* Set the background size to cover the entire element */
	background-size: cover;
	height: 250px;
	color: #000000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
}

.about-founder-title {
	font-size: 47px;
	line-height: 20px;
	font-weight: 700;
	text-align: center;
	color: #000000;
	font-family: AvenirNextLTPro;
}

.about-founder-description {
	font-size: 20px !important;
	line-height: 20px;
	font-weight: 400;
	color: #000000;
	font-family: AvenirNextLTPro;
	margin-top: 15px;
	text-align: center;
}

.about-bottom-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.about-second-left-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10px;
}

/* .about-second-title {
    font-size: 47px;
    font-weight: 700;
} */

.about-second-description {
	letter-spacing: 0.4px;
	line-height: 27px;
}

.about-second-right-container {
	display: flex !important;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 480px) {
	.about-top-container {
		padding: 10%;
	}
	.about-fourth-title {
		font-size: 36px;
		line-height: normal;
		white-space: nowrap;
	}
	.about-fourth-description {
		font-size: 16px;
	}
	.about-founder-title {
		margin: 20px 0px;
		line-height: normal;
		font-size: 36px;
	}
	.about-fourth-section-container {
		padding: 0;
	}
	.about-founder-container {
		padding: 40px;
	}
	.vector12 {
		width: 320px !important;
		margin-bottom: 10%;
	}
	.about-founder-description {
		text-align: justify;
		line-height: normal;
	}
}

@media screen and (min-width: 481px) and (max-width: 767px) {
	.about-top-container {
		padding: 10%;
	}
	.about-fourth-title {
		font-size: 26px;
	}
	.about-fourth-section-container {
		padding: 0% 0%;
	}
	.about-founder-container {
		padding: 30px;
	}
	.about-fourth-title {
		font-size: 30px;
	}
	.about-fourth-description {
		font-size: 16px;
	}
	.vector12 {
		width: 320px !important;
		margin-bottom: 10%;
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.about-top-container {
		padding: 10%;
	}
	.about-fourth-title {
		font-size: 36px;
	}
	.about-fourth-section-container {
		padding: 0;
	}
	.about-founder-container {
		padding: 30px;
	}
	.vector12 {
		width: 320px !important;
		margin-bottom: 10%;
	}
}

@media screen and (min-width: 576px) {
	.about-top-container {
		padding: 10% 24%;
	}
	.vector12 {
		width: 600px !important;
		margin-bottom: 5%;
	}
}

@media screen and (min-width: 860px) {
	.about-founder-section-container {
		padding: 50px 100px;
	}
	.about-founder-container {
		padding: 6% 8%;
	}
	.about-top-container {
		padding: 6% 15%;
	}

	.about-fourth-section-container {
		padding-top: 100px;
	}

	.vector12 {
		width: 900px !important;
		margin-bottom: 0%;
	}
}
