/* Example.css */

.container {
	width: 80%;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	justify-items: center;
	align-items: center;
	padding-bottom: 50px;
}

.about-image {
	max-width: 100%;
	height: auto;
}

.about-title {
	text-wrap: nowrap;
	font-size: 47px;
	font-weight: 700;
	font-family: AvenirNextLTPro;
}

.about-description {
	letter-spacing: 0.4px;
	color: #fff;
	line-height: 27px;
	font-family: AvenirNextLTPro;
	font-size: 18px !important;
	text-align: justify;
	hyphens: auto;
}

.text-right,
.text-left {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.text-right {
	padding: 0 20% 0 0;
}
.text-left {
	padding: 0 0 0 20%;
}
.content-left .image-class,
.content-right .image-class {
	width: 400px;
}

@media (max-width: 480px) {
	.text-right,
	.text-left {
		padding: 25px 0px;
	}
	.about-title {
		font-weight: 500px;
		font-size: 36px;
		text-align: center;
	}
	.text-left {
		order: 2;
	}
	.image-class {
		height: 25rem;
	}

	.content-left .image-class,
	.content-right .image-class {
		width: 100vw;
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.text-right {
		padding: 25px 0 25px 40px;
	}

	.text-left {
		padding: 25px 40px 25px 0;
	}

	.about-title {
		font-weight: 500px;
		font-size: 36px;
	}

	.image-class {
		height: 25rem;
	}

	.content-right .image-class {
		margin-left: 30px;
	}
}
