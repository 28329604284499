/* @font-face {
	font-family: outlined;
	src: url('../../../assets/fonts/Goffik-O.ttf');
} */

.second-explore-btn-gred {
	background: #00ffff;
	color: #000000;
	font-size: 13px;
	height: 40px;
	border: 1px solid #f7f7f7;
	font-family: AvenirNextLTPro;
	width: 220px;
	font-weight: 600;
}

.second-explore-btn-gred:hover {
	background: white;
	color: #04b2a9 !important;
	border: 1px solid #04b2a9 !important;
}

/* container */
.second-main-container {
	height: auto;
	align-items: center;
	background-color: #000000;
}

/* left container */
.second-left-container {
	display: flex;
	padding: 40px;
}
.second-left-sub-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 20px;
}

.second-title {
	color: rgb(255, 255, 255);
	/* font-family: 'M PLUS Rounded 1c', sans-serif; */
	font-size: 47px;
	font-weight: 600;
	line-height: 60px;
	font-family: AvenirNextLTPro;
	text-shadow: none;

	transition: all 0.6s linear; /* vendorless fallback */
	-o-transition: all 0.6s linear; /* opera */
	-ms-transition: all 0.6s linear; /* IE 10 */
	-moz-transition: all 0.6s linear; /* Firefox */
	-webkit-transition: all 0.6s linear; /*safari and chrome */
}

.second-sub-title {
	font-size: 20px !important;
	color: rgb(255, 255, 255);
	font-weight: 400;
	line-height: 30px;
	font-family: AvenirNextLTPro;
}

.second-description {
	font-size: 16px;
	line-height: 28px;
	font-weight: 400;
	font-family: AvenirNextLTPro;
}

.second-read-more-btn {
	color: black;
	width: 0%;
	font-weight: 500;
	padding: 0px !important;
}

/* right container */
.second-right-container {
	background-image: url('../../../../public/images/helmet-mockup-2.png');
	background-position: center;
	width: 100%;
	height: 100%;
	background-size: cover;
	filter: grayscale(100%);
}

.second-style {
	font-family: outlined;
	position: absolute;
	bottom: -44px;
	left: -7.7rem;
	letter-spacing: 15px;
	font-weight: 900;
	font-size: 100px;
	z-index: 100;
	color: #b8b6b6;
	mix-blend-mode: difference;
}

@media screen and (max-width: 480px) {
	.second-left-container {
		padding: 45px 35px;
	}

	.second-right-container {
		min-height: 450px;
	}

	.second-title {
		font-size: 32px;
		line-height: 40px;
	}

	.second-style {
		font-size: 50px;
		bottom: -10px;
		left: calc(50% - 140px) !important;
	}
}

@media screen and (min-width: 481px) and (max-width: 767px) {
	.second-left-container {
		padding: 45px 35px;
	}

	.second-title {
		font-size: 32px;
		line-height: 35px;
	}

	.second-style {
		left: -7rem !important;
		font-size: 85px;
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.second-main-container {
		overflow: hidden;
		height: 50vh;
		align-items: center;
	}
	.second-left-container {
		padding: 45px 35px;
	}

	.second-right-container {
		height: 450px;
	}

	.second-title {
		font-size: 32px;
		line-height: 50px;
	}

	.second-style {
		left: -7rem !important;
		font-size: 60px;
		bottom: 0.5rem;
	}
}

@media screen and (min-width: 860px) {
	.second-main-container {
		height: 100vh;
	}

	.second-left-container {
		padding-left: 150px;
		padding-right: 30px;
	}
}
