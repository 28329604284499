.client-section-container {
	height: auto;
}

.client-left-container {
	height: 100%;
	padding: 13%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.client-left-sub-container {
	gap: 25px;
	display: flex;
	justify-content: start;
	flex-direction: column;
}

.client-left-sub-container .ant-image {
	display: block;
	position: relative;
}

.quote-image {
	position: absolute;
	width: 40px;
	top: -4.5rem;
	color: #fff;
}

.client-title {
	font-size: 47px;
	font-weight: 600;
	color: #fff;
	line-height: 55px;
	font-family: AvenirNextLTPro;
	white-space: nowrap;
}

.client-right-container {
	height: 100%;
	display: flex;
	align-items: center;
	position: relative;
	padding: 5% 0;
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);
}

.card-wrapper {
	width: 500px !important;
	display: flex;
	flex: 0 0 auto;
	margin-right: 15px;
}

.client-card-container {
	box-shadow: 5px 5px 10px #aaaaaa92;
	background-color: transparent;
	border-radius: 15px;
	width: 70%;
	/* height: 250px; */
	overflow: hidden;
}

.client-right-container::before {
	content: '';
	position: absolute;
	top: 0;
	left: 20%;
	width: 80%;
	height: 100%;
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);

	z-index: -1;
}

.client-card-avatar {
	border: 2px solid #dbfffd;
	border-radius: 50px;
}

.client-card-avatar-container {
	gap: 20px;
	display: flex;
	align-items: center;
}

.client-card-name-container {
	display: flex;
	flex-direction: column;
}

.client-card-name {
	color: #04b2a9;
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0.4px;
	font-family: AvenirNextLTPro;
}

.client-card-name-secondary {
	font-family: AvenirNextLTPro;
}

.client-card-desc-text {
	color: #080305;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0.4px;
	font-family: AvenirNextLTPro;
}

.client-card-description-container {
	margin-top: 7%;
}

.slick-slider-horizontal {
	display: flex;
	flex-direction: row;
	overflow-x: auto;
	overflow-y: hidden;
	margin-bottom: 15px; /* Adjust this value as needed */
}

.client-card-content {
	background-color: white;
	padding: 5% 5%;
	height: 100%; /* Set height to 100% */
	border-radius: 15px; /* Apply border radius to the content area */
	box-shadow: 5px 5px 10px #aaaaaa92;
}

.client-test-slick-btn-container {
	margin-left: -15px !important;
}

@media screen and (max-width: 480px) {
	.client-title {
		font-size: 24px;
		line-height: 40px;
	}
	.scooty-card-wrapper {
		display: flex;
		justify-content: center;
	}
	.client-section-container {
		height: max-content;
		padding: 0px;
	}

	.client-card-container {
		width: 90% !important;
	}
	.quote-image {
		width: 40px !important;
		top: -3rem;
	}

	.client-right-container {
		padding: 30px 15px;
		background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);
	}

	.slick-track {
		left: -24px !important;
	}

	.card-wrapper {
		width: 100% !important;
		margin-right: 0;
	}

	.client-card-description-container {
		padding: 5px;
	}
}

@media screen and (min-width: 481px) and (max-width: 767px) {
	.client-title {
		font-size: 32px;
		line-height: 35px;
	}

	.client-section-container {
		height: max-content;
		padding: 0px;
	}

	.slick-slider-horizontal .slick-slide > div {
		width: 54rem;
	}

	.quote-image {
		width: 40px !important;
		top: -3.5rem;
	}

	.client-right-container {
		padding: 30px 15px;
		background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);
	}

	.slick-track {
		left: -24px !important;
	}

	.card-wrapper {
		width: 100% !important;
		margin-right: 0;
	}

	.client-card-description-container {
		padding: 5px;
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.client-title {
		font-size: 32px;
		line-height: 35px;
	}

	.client-section-container {
		height: 40vh;
		padding: 0px;
	}

	.client-card-container {
		width: 82%;
	}
}

@media screen and (min-width: 576px) {
	.client-section-container {
		margin-bottom: 0%;
	}

	.client-left-container {
		padding: 3% 4%;
	}
}

@media screen and (min-width: 860px) {
	.client-section-container {
		height: 100vh;
		margin-bottom: 0%;
	}

	.client-left-container {
		padding: 6% 8%;
	}
}
