/* container */
.vision-first-section-container {
	padding: 5% 8%;
	/* border-top: 1.4px solid rgb(234, 234, 234); */
	align-items: center;
}

.vision-first-sub-container {
	border-radius: 30px;
	padding-top: 5%;
}

.vision-top-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: fit-content;
}

/* title */
.vision-first-title {
	font-weight: 600;
	font-family: AvenirNextLTPro;
	color: #fff;
	text-align: left;
	font-size: 47px;
	transition: all 0.6s linear; /* vendorless fallback */
	-o-transition: all 0.6s linear; /* opera */
	-ms-transition: all 0.6s linear; /* IE 10 */
	-moz-transition: all 0.6s linear; /* Firefox */
	-webkit-transition: all 0.6s linear; /*safari and chrome */
}

/* right container*/
.vector-img {
	width: 300px !important;
	height: 450px !important;
}

.vision-first-title:hover {
	text-shadow: 0 0 5px rgb(136, 244, 148), 0 0 10px rgb(136, 244, 148),
		0 0 20px rgb(136, 244, 148), 0 0 30px rgb(136, 244, 148);
}

.vision-first-description {
	font-size: 22px;
	letter-spacing: 0.4px;
	font-weight: 500;
	color: #fff;
	line-height: 30px;
	font-family: AvenirNextLTPro;
	text-align: justify;
	hyphens: auto;
}

.vision-first-explore-btn {
	font-family: AvenirNextLTPro;
}

.vision-bottom-container {
	position: absolute;
	display: flex;
	justify-content: center;
	width: 100%;
	bottom: 0px;
}

@media screen and (max-width: 768px) {
	.first-left-container {
		padding: 0px !important;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 10px;
	}
	.vision-first-section-container {
		height: max-content;
	}

	.vision-first-description {
		font-size: 20px;
	}

	.vision-first-title {
		font-size: 30px;
		text-align: left;
	}
	.first-left-sub-container {
		gap: 10px;
	}
	.vision-second-description {
		text-align: left;
	}
	.vision-third-title {
		font-size: 28px;
		line-height: 36px;
	}
}

@media screen and (min-width: 576px) {
	.vision-first-section-container {
		height: 50vh;
	}
	.vision-first-section-container {
		padding: 5%;
	}
	.vision-first-title {
		font-size: 47px;
		text-align: center;
	}
	.first-left-container {
		padding: 0px !important;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

@media screen and (min-width: 860px) {
	.vision-first-section-container {
		height: 120vh;
	}

	.vision-first-sub-container {
		background: linear-gradient(to bottom, #f0f0f0, #ffffff);
	}

	.vision-first-title {
		text-align: left;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.vision-first-section-container {
		height: auto !important;
		padding: 8% 5%;
	}
	.vision-first-title {
		text-align: start;
		font-size: 36px !important;
	}
}
