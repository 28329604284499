.vision-fourth-left-container {
	background-image: url('../../../../public/images/scooty.png');
	background-position: center;
	background-size: cover;
	filter: grayscale(100%);
	height: 100%;
}

.vision-fourth-title {
	text-transform: uppercase;
	font-weight: 700;
	font-family: AvenirNextLTPro;
}

.vision-fourth-right-container {
	padding: 0% 8%;
	display: flex !important;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.vision-fourth-img {
	filter: grayscale(100%);
}

.vision-fourth-section-container {
	background-color: #000000;
}

@media screen and (min-width: 200px) {
	.vision-fourth-section-container {
		height: auto;
		margin: 10px;
	}

	.vision-fourth-title {
		font-size: 30px;
		text-align: left;
		margin: 10px 0px;
		color: #fff;
	}
}

@media screen and (min-width: 576px) {
	.vision-fourth-section-container {
		height: 40vh;
	}

	.vision-fourth-title {
		font-size: 36px;
	}
}

@media screen and (min-width: 860px) {
	.vision-fourth-section-container {
		height: 90vh;
		background-color: #000000;
	}

	.vision-fourth-title {
		font-size: 47px;
	}
}
