.third-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 255px;
	margin: 10px;
	color: #fff;
}

.ant-card {
	background-color: #000000 !important;
	color: #ffff;
}

.ant-rate-star:not(.ant-rate-star-zero) {
	color: #ff8f28;
}

.third-card-hover {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	margin: 20px 0px;
}

.third-card-img-container {
	width: 100%;
	border-bottom: 1px solid rgb(236, 236, 236);
	margin: 10px 0px;
	position: relative;
	background-color: #f2f2f2;
}

.pdp-card-image {
	height: 450px;
	max-height: 450px;
}

.card-details-container {
	gap: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.third-card-title {
	font-size: 20px;
	font-weight: 500;
	color: #ffff !important;
}

.third-card-price {
	color: #00ffff;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: 0.4px;
}

/* new product card component css */

.product-card {
	width: 100%;
	margin: 10px;
	border: 1px solid #1d1e18;
	border-radius: 20px;
	background-color: #000000;
	padding: 10px;
}

.product-card {
	box-shadow: 0 10px 15px -3px rgba(29, 30, 24, 0.1),
		0 4px 6px -4px rgba(29, 30, 24, 0.1);
}

.product-card-img-container {
	padding: 30px 0;
	margin: 5px;
	border-radius: 6px;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.product-card-img-container .discount-percent-display {
	width: 47px;
	height: 55px;
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);
	position: absolute;
	top: 0;
	right: 0;
	border-top-right-radius: 5px;
	border-bottom-left-radius: 15px;
	padding: 5px;
	z-index: 10;
}

.product-card-img-container .discount-percent-display .discount-percent-text {
	color: #000000;
	font-weight: 600;
	font-size: 16px;
	font-family: AvenirNextLTPro;
}

.ant-rate .ant-rate-star-second {
	color: #fff;
}
.product-card-img {
	background-color: transparent; /* already defined */
	width: 80%;
	height: 150px;
	object-position: center;
	object-fit: contain;
	mix-blend-mode: multiply;
	filter: blur(0px); /* Adjust the blur value as needed */
}

.product-details-container {
	padding: 10px 0 !important;
}

.product-card .product-card-title {
	font-size: 22px;
	color: #ffffff;
	padding: 10px 0;
	font-family: AvenirNextLTPro;
	white-space: nowrap;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}

.product-card-discounted-price {
	color: #00ffff;
	font-size: 19px;
	font-weight: 600;
	font-family: AvenirNextLTPro;
}

.product-card-original-price {
	color: #8f8f99;
	font-size: 15px;
	font-weight: 600;
	margin-top: 3px;
	margin-left: -15px;
	font-family: AvenirNextLTPro;
}

.product-card .product-specification-value {
	font-size: 16px;
	font-weight: 600;
	color: #ffffff;
	font-family: AvenirNextLTPro;
	white-space: nowrap;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}

.product-card .product-specification-text {
	font-size: 12px;
	font-weight: 600;
	color: #ffffff;
	font-family: AvenirNextLTPro;
}

.product-card-enable-btn {
	background-image: linear-gradient(to right, #03746a, #18d991);
	width: 100%;
	color: #fff;
	border-radius: 5px;
	height: 40px;
	font-size: 14px;
	font-weight: 600;
	border: none;
	font-family: AvenirNextLTPro;
}

.product-card-enable-btn:hover {
	color: #fff !important;
	border: none !important;
	box-shadow: 0px 0px 10px #aaaaaa92;
}

.product-card-disable-btn {
	background-color: #fff;
	width: 100%;
	color: #080305;
	border-radius: 5px;
	height: 40px;
	font-size: 14px;
	font-weight: 600;
	border: 1px solid #e3e3e3 !important;
	font-family: AvenirNextLTPro;
}

.product-card-disable-btn:hover {
	color: #080305 !important;
	border: 1px solid #e3e3e3 !important;
	box-shadow: 0px 0px 10px #aaaaaa92;
}

.filter-btn-container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent !important;
	position: fixed;
	bottom: 0;
	left: calc(50% - 100px);
}

.filter-btn-container-header,
.filter-btn-no-products-container {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
}

.filter-btn {
	background-color: #04b2a9;
	color: #fff;
	font-family: AvenirNextLTPro;
	font-weight: 600;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	width: 100px;
	height: 40px;
}

.sort-btn {
	background-color: #04b2a9;
	color: #fff;
	font-family: AvenirNextLTPro;
	font-weight: 600;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	width: 100px;
	height: 40px;
}

.responsive-drawer .ant-drawer-header {
	padding: 10px 15px !important;
	font-family: AvenirNextLTPro;
}

.responsive-drawer .ant-drawer-header .ant-drawer-close {
	margin: 0 !important;
}

.responsive-drawer .ant-drawer-body {
	padding: 0 30px !important;
}

.responsive-drawer .ant-drawer-body::-webkit-scrollbar {
	display: none;
}

@media screen and (min-width: 200px) {
	.product-card {
		margin: 0;
	}

	.product-list .ant-list-item {
		margin: 10px;
	}
}

@media screen and (max-width: 480px) {
	/* add small screen css here */
	.product-card-img-container .ant-image .ant-image-img {
		background-color: #f4f4f4;
		width: 180px !important;
		height: 130px !important;
	}
}

@media screen and (min-width: 481px) and (max-width: 767px) {
	/* add landscape screen css here */
	.product-card-img-container .ant-image .ant-image-img {
		background-color: #f4f4f4;
		width: 180px !important;
		height: 130px !important;
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.product-card-img-container .discount-percent-display {
		z-index: 1;
	}
}
