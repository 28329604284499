/* container */
.footer-container {
	background-color: #000000;
}

.footer-top-container {
	padding: 80px 0px 50px;
}

.footer-logo-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0% 6%;
}

.footer-btn {
	background: transparent;
	color: rgb(226, 226, 226);
	height: 40px;
	width: 150px;
	font-size: 13px;
}

.footer-logo-sub-container {
	gap: 14px;
	display: flex;
	flex-direction: column;
}

.footer-logo-text {
	color: rgb(226, 226, 226);
	font-family: AvenirNextLTPro;
}

.footer-menu-container {
	gap: 18px;
	display: flex;
	flex-direction: column;
}

.footer-menu-text {
	cursor: pointer;
	color: rgb(226, 226, 226);
	font-family: AvenirNextLTPro;
}

.logo {
	margin-left: -4%;
	margin-bottom: -3%;
}

.social-media-text {
	color: rgb(226, 226, 226);
	font-weight: 500;
	font-family: AvenirNextLTPro;
}

.footer-icons {
	color: white;
	margin: 2px;
	font-size: 18px;
}

/* bottom */
.footer-bottom {
	border-top: 1px solid gray;
	background-color: black;
	display: flex;
	padding: 1.2% 0%;
	justify-content: center;
}

.footer-bottom-text {
	font-size: 13px;
	color: rgb(226, 226, 226);
	font-family: AvenirNextLTPro;
}

.quick-links {
	display: none;
}

.footer-contact-us-menu {
	display: none;
}

.footer-legel-terms-responsive-menu {
	display: none;
}

@media screen and (max-width: 1023px) {
	.logo {
		margin-left: 0;
		margin-bottom: -3%;
	}

	.footer-menu-container {
		align-items: center;
	}

	.footer-contact-us-menu,
	.footer-legel-terms-responsive-menu {
		text-align: center;
	}

	.quick-links {
		display: block;
	}

	.quick-links .quick-links-text {
		font-weight: 600;
		font-size: 20px;
		color: #fff;
		font-family: AvenirNextLTPro;
	}

	.footer-contact-us-menu {
		display: block;
		gap: 18px !important;
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
	}

	.footer-divider {
		width: 50px;
		background-color: #fff;
	}

	.footer-legel-terms-menu {
		display: none;
	}

	.footer-legel-terms-responsive-menu {
		display: block;
	}

	.footer-bottom {
		padding: 10px 0;
	}
}

@media screen and (min-width: 200px) {
	.footer-container {
		margin-top: 0%;
	}

	.footer-logo-sub-container {
		align-items: center;
	}

	.footer-top-container {
		gap: 25px;
	}
}

@media screen and (min-width: 576px) {
	.footer-container {
		margin-top: 0%;
	}

	.footer-logo-sub-container {
		justify-content: center;
		align-items: center;
	}
	.footer-top-container {
		padding: 50px 0px 125px;
	}
}

@media screen and (min-width: 860px) {
	.footer-container {
		margin-top: 0%;
	}

	.footer-logo-sub-container {
		justify-content: start;
		align-items: start;
	}

	.footer-top-container {
		gap: 0px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.footer-top-container {
		padding: 20px;
	}
}
