.first-section {
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);

	height: 200px;
	align-content: center;
}
.heading {
	text-align: center;
	font-family: 'Avenir Next LT Pro', sans-serif;
	text-transform: uppercase;
	color: #000000 !important;
}
.product-main-information {
	width: 1200px;
}

.ant-modal .ant-modal-header {
	background-color: transparent !important;
}

.ant-modal .ant-modal-header .ant-modal-title {
	color: #00ffff !important;
}
.label-style {
	color: #ffffff;
	font-weight: 400;
}

h2 .ant-typography,
div .ant-typography-h2,
div .ant-typography-h2 > textarea,
.ant-typography h2 {
	color: #fff !important;
}

.ant-checkbox + span {
	color: #fff;
}
.PU-input {
	color: #000 !important;
	margin-top: 10px;
	/* text-align: left; */
}
.PU-input::placeholder {
	color: #848397;
	font-weight: 500;
}
.save-btn {
	color: rgb(0, 0, 0);
	font-weight: 500;
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);

	height: 50px;
	width: 200px;
}
.cancel-btn {
	color: #000000;
	font-weight: 500;
	border: 1px solid #00ffff;
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);
	height: 50px;
	width: 200px;
}
.cancel-btn:hover {
	color: #000000 !important;
}
.container {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	padding-right: 15px;
	padding-left: 15px;
	color: #fff;
}
@media (min-width: 476px) {
	.container {
		padding-right: 15px;
		padding-left: 15px;
	}
}
@media (min-width: 768px) {
	.container {
		padding-right: 15px;
		padding-left: 15px;
	}
}
@media (min-width: 992px) {
	.container {
		padding-right: 15px;
		padding-left: 15px;
	}
}
@media (min-width: 1200px) {
	.container {
		padding-right: 15px;
		padding-left: 15px;
	}
}
@media (min-width: 476px) {
	.container {
		width: 100%;
	}
}
@media (min-width: 768px) {
	.container {
		width: 720px;
		max-width: 100%;
	}
}
@media (min-width: 992px) {
	.container {
		width: 960px;
		max-width: 100%;
	}
}
@media (min-width: 1200px) {
	.container {
		width: 1140px;
		max-width: 100%;
	}
}
@media (min-width: 1400px) {
	.container {
		width: 1340px;
		max-width: 100%;
	}
}
