.vision-second-section-container {
	overflow: hidden;
	background-color: #000000;
	align-items: center;
}

.vision-second-left-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 30px;
	align-items: center;
}

.vision-second-title {
	font-weight: 700;
	line-height: 55px;
	color: white;
	font-family: AvenirNextLTPro;
}

.vision-second-title span {
	color: #19db92;
}

.vision-second-description {
	color: white;
	letter-spacing: 0.4px;
	line-height: 27px;
	font-family: AvenirNextLTPro;
	font-size: 18px !important;
	text-align: justify;
	hyphens: auto;
}

.vision-second-right-container {
	display: flex;
	justify-content: center;
	align-items: end;
}

.vision-hands-img {
	opacity: 20%;
	height: 400px !important;
	object-fit: contain;
}

@media screen and (max-width: 480px) {
	.vision-second-section-container {
		height: max-content;
	}

	.vision-second-left-container {
		padding: 10% 6%;
		text-align: center;
	}

	.vision-second-title {
		font-size: 28px;
		text-align: left;
		line-height: 35px;
	}
	.vision-hands-img {
		opacity: 20%;
		height: 225px !important;
	}
	.vision-second-description {
		word-spacing: -1px;
		line-break: auto;
	}
}

@media screen and (min-width: 576px) {
	.vision-second-section-container {
		height: 50vh;
	}

	.vision-second-left-container {
		padding: 6% 6%;
	}

	.vision-second-title {
		font-size: 47px;
	}
}

@media screen and (min-width: 860px) {
	.vision-second-section-container {
		height: 85vh;
	}

	.vision-second-left-container {
		padding: 0% 6%;
		text-align: left;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.vision-second-title {
		font-size: 32px;
	}

	.vision-second-left-container {
		gap: 0px;
	}

	.vision-second-section-container {
		height: auto;
	}
}
