
.brand-section-container {
    background-color: white;
}
.brand-image-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    justify-items: center;
    align-items: center;
    width: 100%;
    padding: 20px;
  }
  
  .brand-image-container img {
    width: 100px; /* Adjust the size according to your needs */
    height: auto;
    object-fit: contain; /* Ensures the image fits within the container without distortion */
  }
  
  @media (max-width: 768px) {
    .brand-image-container {
      grid-template-columns: repeat(3, 1fr); /* Adjust for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .brand-image-container {
      grid-template-columns: repeat(2, 1fr); /* Stack more images on smaller screens */
    }
  }
  