.promotion-section-container {
	height: auto !important;
	margin: 0px 0px;
	width: 100% !important;
	overflow: hidden;
	overflow: hidden;
	box-sizing: border-box !important;
	/* background-image: url('https://img.freepik.com/free-vector/gradient-background-#00FFFF-tones_23-2148373477.jpg?w=996&t=st=1718976105~exp=1718976705~hmac=4f6a40baadcc1f44d8c765856bd187807bd286771a5e3c92a62762076b700e70'); */
	background: linear-gradient(
		to bottom right,
		white 50%,
		#0f0f0f 50%,
		#000000 100%
	);
	background-position: center;
	background-size: contain;
	padding: 30px 10px;
}

.promotion-text-overlay {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	padding: 0px;
	padding-left: 20px;
	justify-content: center;
	box-sizing: border-box;
	align-items: start;
	background-color: #f2f2f2;
	gap: 13px;
	height: 100%;
	border-left: 1px solid #3eedd3;
	color: #000000;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.offer-title {
	height: 30% !important;
	width: 100%;
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.promotion-card-wrapper {
	width: 300px;
	display: grid !important;
	background-color: #fff;
	grid-template-columns: 1fr 1fr;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #f2f2f2;
	padding: 0px 0px;
	border-radius: 10px;
	max-height: 270px;
	min-height: 270px;
	position: relative;
	transform-origin: center center;
	transition: all 0.2s, border-color 0.2s;
	margin: 0px 20px;
	box-shadow: 0 10px 20px -5px rgba(128, 128, 128, 0.3),
		0 20px 25px -5px rgba(128, 128, 128, 0.2),
		0 5px 10px -3px rgba(128, 128, 128, 0.15);
}

.promotion-slider-horizontal .slick-slide {
	margin: 0 20px;
	box-sizing: border-box; /* Adjust the value as needed */
}

.promotion-slider-horizontal .slick-list {
	margin: 0 -10px; /* Adjust this to counteract the slide margin */
}
.first-explore-btn-book {
	background: #fff;
	border: 1px solid #f2f2f2;
	color: #19db92;
	font-size: 14px;
	font-weight: bold;
	height: 30px;
	border: 1px solid #f7f7f7;
	font-family: AvenirNextLTPro;
	font-weight: 600;
	padding: 5px 20px;
	border-radius: 7px;
	width: 70%;
	text-align: center;
	box-shadow: 0 10px 20px -5px rgba(128, 128, 128, 0.3),
		0 20px 25px -5px rgba(128, 128, 128, 0.2),
		0 5px 10px -3px rgba(128, 128, 128, 0.15);
}

.offer-title p {
	background-color: #3af9b0;
	width: fit-content;
	padding: 10px;
}

.first-explore-btn-book:hover {
	color: white !important;
	border: 1px solid #04b2a9 !important;
	background: linear-gradient(to right, #04b2a9, #19db92);
}

.first-title-content {
	/* background-image: linear-gradient(to right, #007771, #19db92); */
	width: fit-content;
	color: rgb(0, 0, 0) !important;
	/* font-family: 'M PLUS Rounded 1c', sans-serif; */
	font-size: 1.2rem !important;
	font-weight: 700 !important;
	line-height: 60px;
	font-family: AvenirNextLTPro;
	letter-spacing: 2px;
	text-shadow: none;
	padding: 0px 0px;
	line-height: 25px;
	border-radius: 7px;
	transition: all 0.6s linear; /* vendorless fallback */
	-o-transition: all 0.6s linear; /* opera */
	-ms-transition: all 0.6s linear; /* IE 10 */
	-moz-transition: all 0.6s linear; /* Firefox */
	-webkit-transition: all 0.6s linear; /*safari and chrome */
}

.first-title-content:hover {
	text-shadow: 0 0 5px rgb(136, 244, 148), 0 0 10px rgb(136, 244, 148),
		0 0 20px rgb(136, 244, 148), 0 0 30px rgb(136, 244, 148);
}

.promotion-card-wrapper:hover {
	transform: scale(1.001);
}

.promotion-text-overlay span {
	font-family: AvenirNextLTPro;
	font-size: 16px;
	font-weight: 600;
	margin-top: 5px;
	/* text-align: justify; */
}
.promotion-slider-horizontal {
	width: 100%;
	margin: 20px;
}

.product-card-enable-btn {
	background-image: linear-gradient(to right, #03746a, #18d991);
	width: 100%;
	color: #fff;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 600;
	border: none;
	font-family: AvenirNextLTPro;
	padding: 10px 15px;
}

.product-card-enable-btn:hover {
	color: #fff !important;
	border: none !important;
	box-shadow: 0px 0px 10px #aaaaaa92;
}
.promotion-image-container {
	position: relative;
	display: inline-block;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 100%;
	padding: 10px;
	position: relative;
	margin: 10px 0px;
}
.promotion-image-container span {
	position: absolute;
	top: 20px;
	left: 10px;
	font-size: 1rem;
}
.promotion-image-container img {
	object-fit: contain;
	object-position: center;
	width: 70%;
	max-height: 50%;
	max-height: 120px;
	border-radius: 10px;
}

.brand-image {
	position: absolute;
	max-width: 50px;
	object-fit: contain;
	bottom: 10px;
	left: 0px;
}

.promotion-card-avatar {
	width: 100%;
}
.card {
	border: 0px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: none !important;
	width: 100%;
	padding: 0px !important;
	background-color: transparent;
	margin: 0px 10px;
}

.ant-card-body {
	padding: 5px;
	margin: 20px;
}
@media screen and (max-width: 900px) {
	.promotion-card-wrapper {
		width: 100% !important;
		padding: 10px 0px;
		height: 300px;
	}

	.card {
		padding: 0px !important;
		width: 600px !important;
	}
	.promotion-section-container {
		height: 50vh !important;
	}
}

.big {
	display: none !important;
}

.ribbon {
	font-size: 20px;
	position: absolute;
	display: inline-block;
	top: -7%;
	left: -0%;
	min-width: 48%;
	max-width: 48%;
	text-align: center;
}
.text {
	display: inline-block;
	padding: 0.5em 1em;
	margin: 0;
	min-width: 90%;
	font-size: 16px;
	max-width: 100%;
	line-height: 1.2em;
	background-image: linear-gradient(to right, #007771, #19db92);
	position: relative;
	font-family: AvenirNextLTPro;
	font-style: italic;
	font-weight: 600;
	color: #ffffff;
}

span {
	font-family: AvenirNextLTPro;
}

.banner {
	width: 80%;
	background: rgb(246, 21, 21);
	height: 100%;
	overflow: hidden;
	display: flex;
	justify-content: flex-start;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
	cursor: pointer;
	border-radius: 0.3em;
	position: relative;
}

.shopping-image {
	height: auto;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.brandImage {
	position: absolute;
	width: 50px;
	object-position: center;
	bottom: 2px;
	left: 2px;
}

.shopping-image > img {
	width: 90%;
	object-fit: contain;
	padding: 10px;
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	border-radius: 10px;
	background-color: #ebeced;
}

.banner .text-box {
	display: flex !important;
	justify-content: space-around;
	flex-direction: column;
	text-align: center;
	width: 50%;
}

.text-box div {
	background-color: #2d2d2b;
	padding: 20px !important;
	font-size: 2.5rem;
	color: yellow;
	line-height: 45px;
	padding: 0px 20px;
	font-weight: bold;
	margin: auto;
	border-radius: 8px;
	width: 60%;
	/* animation: glow 4s infinite; */
}

@keyframes glow {
	0% {
		text-shadow: 0 0 3px rgba(255, 255, 224, 0.8),
			/* Yellow */ 0 0 10px rgba(255, 255, 224, 0.8),
			0 0 20px rgba(173, 255, 47, 0.8),
			/* Mid-#00FFFF */ 0 0 30px rgba(173, 255, 47, 0.8),
			0 0 40px rgba(255, 255, 255, 0.8),
			/* White */ 0 0 50px rgba(255, 255, 255, 0.8);
	}
}

.text-box > div :nth-child(1) {
	font-size: 3rem;
	color: rgb(0, 255, 174);
	font-weight: bold;
	line-height: 0px;
	margin: 0px 0px;
}
.text-box span {
	font-size: 2rem;
	color: rgb(255, 255, 255);
	font-weight: bold;
	line-height: 0px;
	margin: 0px 0px;
}

a {
	color: #fff;
}
@media screen and (max-width: 768px) {
	.text-box > div :nth-child(1) {
		font-size: 1.5rem !important;
		color: rgb(0, 255, 174);
		font-weight: bold;
		line-height: 0px;
		margin: 0px 0px;
	}
	.ribbon {
		font-size: 20px;
		position: absolute;
		display: inline-block;
		top: -7%;
		left: -0%;
		min-width: 45%;
		max-width: 45%;
		text-align: center;
	}
	.promotion-slider-horizontal .slick-slide {
		margin: 0 0px;
		box-sizing: border-box; /* Adjust the value as needed */
	}

	.promotion-slider-horizontal .slick-list {
		margin: 0 -0px; /* Adjust this to counteract the slide margin */
	}

	.ant-card-body {
		padding: 5px !important;
		margin: 0px 30px;
	}

	.text-box {
		font-size: 1rem !important;
	}

	.text-box div {
		font-size: 1.6rem !important;
	}

	.text-box span {
		font-size: 1rem;
		color: rgb(255, 255, 255);
		font-weight: bold;
		line-height: 0px;
		margin: 0px 0px;
	}
	.promotion-card-wrapper {
		width: 92% !important;
		padding: 0px 0px;
		margin: 0px 0px;
	}

	.card {
		padding: 0px !important;
		width: 100vw !important;
	}
	.slick-track {
		left: 0px !important;
	}
	.promotion-slider-horizontal {
		margin: 0px 20px;
	}
	.promotion-section-container {
		height: 115vh !important;
	}

	.btn-icon {
		display: none !important;
	}
	.small {
		display: none;
	}
	.big {
		display: flex !important;
	}

	/* .promotion-card-avatar {
		height: 40vh;
		width: 100%;
		object-fit: cover;
		object-position: center;
	} */
	.product-title {
		font-size: 15px;
		font-weight: 600;
		letter-spacing: 0.4px;
		font-family: AvenirNextLTPro;
	}
	span {
		font-size: 14px;
		font-weight: 400;
	}
	.promotion-text-overlay {
		padding: 10px 20px;
		gap: 10px;
	}
	.shopping-image > img {
		width: 90% !important;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.promotion-card-wrapper {
		width: 100% !important;
		padding: 10px 0px;
		height: 300px;
	}

	.card {
		padding: 0px !important;
		width: 400px !important;
	}

	.promotion-slider-horizontal .slick-slide {
		margin: 0px !important;
	}
	.promotion-section-container {
		height: 40vh !important;
	}
}
