.step-top-container {
	display: block;
	justify-content: space-between;
	text-align: left;
	background-color: #1d1e18;
	color: #ffff;
	gap: 10px;
}

/* .step-top-container Col:nth-child(1) {
    background-color: rgb(91, 113, 103);
    opacity: 0.5;
} */

.powering-text {
	display: flex;
	flex-direction: column;
	justify-content: left;
	text-align: left;
	color: #ffff;
	gap: 10px;
}

.overlay {
	position: relative;
}

.overlay::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(
		0,
		0,
		0,
		0.4
	); /* Adjust the color and opacity as needed */
	backdrop-filter: blur(6px);
	border-bottom: 1px solid #e3e3e3;
}

.battery-image-section {
	margin-top: 80px;
}

.step-title {
	font-family: AvenirNextLTPro;
	font-size: 40px;
	font-weight: 600;
	line-height: 70px;
	letter-spacing: 0em;
	color: #ffff;
	color: #04b2a9 !important;
}

.step-sub-title {
	font-family: AvenirNextLTPro;
	font-weight: 600;
	color: #ffff;
}

.step-first {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.step-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.step-description {
	font-family: AvenirNextLTPro;
	font-size: 18px;
	font-weight: 500;
	line-height: 30px;
	letter-spacing: 0em;
	color: #ffff;
	text-align: left;
	margin-top: 10px;
	text-align: justify;
}

@media screen and (min-width: 200px) {
	.step-top-container {
		padding: 10% 10%;
	}

	.battery-image-section {
		margin-top: 10px;
	}

	.vector12 {
		width: 320px !important;
		margin-bottom: 10%;
	}

	.step-first {
		align-items: start;
	}

	.step-sub-title {
		font-size: 25px;
	}
}

@media screen and (min-width: 576px) {
	.step-top-container {
		padding: 10% 24%;
	}

	.vector12 {
		width: 600px !important;
		margin-bottom: 5%;
	}
}

@media screen and (min-width: 860px) {
	/* .about-founder-section-container {
        padding: 5% 10% 2%;
    }
    .about-founder-container {
        padding: 6% 8%;
    } */
	.step-center {
		padding: 0 50px;
	}

	.step-top-container {
		padding: 5% 8%;
		display: flex;
		justify-content: space-between;
		text-align: left;
		gap: 10px;
	}

	.vector12 {
		width: 900px !important;
		margin-bottom: 0%;
	}
}
