.vision-third-left-container {
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
}

.vision-third-title {
	font-size: 47px;
	font-weight: 700;
	color: #fff;
	line-height: 55px;
	font-family: AvenirNextLTPro;
}

.vision-third-sub-title {
	font-size: 18px;
	font-weight: 600;
	line-height: 20px;
	color: #fff;
	font-family: AvenirNextLTPro;
}

.vision-third-description {
	letter-spacing: 0.4px;
	color: #fff;
	line-height: 27px;
	font-family: AvenirNextLTPro;
	font-size: 18px !important;
	text-align: justify;
	hyphens: auto;
}

.vision-third-right-container {
	display: flex !important;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 768px) {
	.vision-third-section-container {
		height: fit-content;
	}
	.vision-third-title {
		font-size: 28px;
		line-height: 36px;
		text-align: left;
	}
	.vision-third-sub-title {
		font-size: 16px;
		font-weight: 600;
		text-align: left;
	}
	.vision-third-left-container {
		text-align: center;
		padding: 8% 8%;
	}
	.vision-third-description {
		letter-spacing: 0.4px;
		font-size: 17px;
		line-height: 25px;
		text-align: left;
	}
}

@media screen and (min-width: 576px) {
	.vision-third-section-container {
		height: 70vh;
	}

	.vision-third-left-container {
		padding: 0% 8%;
	}
	.vision-third-section-container {
		height: 80vh;
	}
}

@media screen and (min-width: 860px) {
	.vision-third-section-container {
		height: 100vh;
	}

	.vision-third-left-container {
		padding: 0% 6%;
		text-align: left;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.vision-third-section-container {
		height: auto;
		margin: 50px 0px;
	}
	.vision-third-title {
		font-size: 36px;
	}
}
