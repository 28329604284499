/* left container */
.third-left-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.third-section-container {
	align-items: center;
	background-color: #000000;
}

.third-left-sub-container {
	display: flex;
	flex-direction: column;
	gap: 25px;
	justify-content: start;
}

/* title */
.third-title {
	font-size: 47px;
	font-weight: 600;
	line-height: 60px;
	font-family: AvenirNextLTPro;
	letter-spacing: 2px;
	color: #fff;
}

.ant-card .ant-card-body {
	padding: 0px;
}
.ant-collapse-expand-icon .accordion-icon-active {
	color: #000000 !important;
}
.third-description {
	font-size: 20px;
	line-height: 28px;
	font-weight: 400;
	font-family: AvenirNextLTPro;
	color: #fff;
}

/* button */
.third-view-all-btn {
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);

	color: rgb(0, 0, 0);
	height: 40px;
	width: 150px;
	font-family: AvenirNextLTPro;
	font-weight: 600;
}

.third-view-all-btn:hover {
	background: white;
	border: 1.4px solid #04b2a9 !important;
}

.slick-btn-container {
	margin-left: 20px;
}

/* right container */
.third-right-container-box {
	display: flex;
	justify-content: center;

	align-items: center;
}

/* cards */
.third-right-container .ant-card .ant-card-body {
	padding: 0px 0px !important;
	width: 230px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.third-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 230px !important;
}

.third-card-img-container {
	padding: 10% 0%;
	border-bottom: 1px solid rgb(236, 236, 236);
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.third-card-img {
	height: 150px !important;
	width: 100%;
	padding: 10px;
}

.card-details-container {
	padding: 10% 0%;
	gap: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.third-card-title {
	font-size: 15px;
	font-weight: 500;
	font-family: AvenirNextLTPro;
}

.third-card-price {
	color: #04b2a9;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: 0.4px;
	font-family: AvenirNextLTPro;
}

.carousel-content {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.horizontal-scroll-container {
	display: flex;
	align-items: center;
	margin: 20px;
}

.scroll-wrapper {
	overflow-x: auto;
	width: 150px;
	/* Adjust to show only 1.5 items */
	border: 1px solid #ccc;
	/* Optional: Add border for visualization */
	margin: 0 10px;
}

.scroll-content {
	display: flex;
}

.item {
	flex: 0 0 auto;
	padding: 10px;
	margin-right: 10px;
	/* Adjust spacing between items as needed */
	background-color: #f0f0f0;
	/* Optional: Add background color for items */
}

.btn-prev {
	margin: 0 20px;
	width: 40px;
	height: 40px;
	padding: 7px 7px;
}

.btn-next {
	width: 40px;
	height: 40px;
	padding: 7px 7px;
}

.btn-prev svg path,
.btn-next svg path,
.btn-prev svg path,
.btn-next svg path {
	fill: #000000;
}

.btn-prev:active,
.btn-next:active,
.btn-prev:hover,
.btn-next:hover {
	border: 1.4px solid #04b2a9 !important;
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);
}

.btn-prev:active svg path,
.btn-next:active svg path,
.btn-prev:hover svg path,
.btn-next:hover svg path {
	fill: #000000;
}
.desktop-view {
	display: flex !important;
}
.small {
	display: none !important;
}

@media screen and (max-width: 480px) {
	.card-wrapper,
	.scooty-card-wrapper {
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
	}
	.small {
		display: flex !important;
		margin-top: 10px;
	}
	.desktop-view {
		display: none !important;
	}
	.scooty-card-wrapper {
		margin: 0 0px;
	}
	.third-title {
		font-size: 28px;
		line-height: 40px;
	}
	.third-card {
		margin: 10px auto;
	}

	.slick-btn-container {
		margin-left: 5px;
	}

	.h {
		margin-top: 0;
		margin-bottom: 0;
		margin-left: calc(50% - 105px);
		margin-right: calc(50% - 105px);
	}

	.third-left-container {
		margin-bottom: 20px;
	}

	.third-section-container {
		padding: 35px 40px;
	}
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
	.card-wrapper {
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
	}

	.third-section-slick-slider .slick-slide > div {
		width: 350px;
	}

	.scooty-card-wrapper {
		margin: 0 50px;
	}

	.third-card {
		width: 80% !important;
		margin: 0;
	}

	.third-title {
		font-size: 32px;
		line-height: 35px;
	}
	.third-section-container {
		padding: 45px 35px;
	}

	.third-left-container {
		padding: 15px 0px;
	}

	.third-right-container {
		padding: 15px 0px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.third-section-container {
		height: 45vh;
	}
	.third-section-container {
		margin: 0px 0;
	}

	.card-wrapper {
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
	}

	.slick-slide > div {
		width: 350px;
	}

	.scooty-card-wrapper {
		margin: 0 50px;
	}

	.third-card {
		width: 80% !important;
		margin: 0;
	}

	.third-title {
		font-size: 32px;
		line-height: 35px;
	}
	.third-section-container {
		padding: 45px 35px;
	}

	.third-left-container {
		padding: 15px 0px;
	}

	.third-right-container {
		padding: 15px 0px;
	}
}

@media screen and (min-width: 860px) {
	.third-section-container {
		height: 80vh;
	}

	.third-left-container {
		padding: 2% 8%;
	}
}
