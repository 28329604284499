.product-search-input {
    border-radius: 30px;
    padding: 0 20px !important;
    font-family: AvenirNextLTPro;
}

.product-sort-input {
    border-radius: 30px;
    font-weight: 600;
    color: #808080;
    font-family: AvenirNextLTPro;
}

@media screen and (min-width: 200px) {
    .product-search-input {
        border-radius: 30px;
        padding: 7px 20px !important;
        font-family: AvenirNextLTPro;
    }
    
    .product-sort-input {
        border-radius: 30px;
        font-weight: 600;
        color: #808080;
        font-family: AvenirNextLTPro;
    }
}
