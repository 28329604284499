.custom-checkbox .ant-checkbox-wrapper {
	color: #ffffff;
	font-family: AvenirNextLTPro;
}

.filter-header {
	font-family: AvenirNextLTPro;
	font-size: 16px;
	color: #fff;
}

.custom-checkbox .ant-checkbox-inner {
	border: 1px solid #e3e3e3;
	border-radius: 0;
	color: #ffffff;
	padding: 2px;
}

/* .custom-checkbox .ant-checkbox-inner::after {
    content: none;
} */

/* .custom-slider .ant-slider-track {
    background-color: #080305 !important;
}

.custom-slider .ant-slider-handle{
    border-color: #080305 !important;
} */
