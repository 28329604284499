.row-with-margin {
	margin: 0 16px;
}

.left-div-border {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.image-alignment {
	display: flex !important;
	justify-content: center;
	width: 100%;
}
.ant-tabs .ant-tabs-tab {
	color: #fff !important;
}
/* .ant-tabs .ant-tabs-tab .ant-tabs-tab-active .ant-tabs-tab-bt {
	color: #00ffff !important;
} */
.left-arrow-alignment,
.right-arrow-alignment {
	display: flex !important;
	width: 100%;
	color: #04b2a9;
}
.left-arrow-alignment {
	justify-content: end;
}
.right-arrow-alignment {
	justify-content: start;
}

.heading-style {
	font-size: 3rem;
	font-weight: 600;
	padding-top: 7%;
}

.location-selector {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.product-img {
	width: 346px;
	height: 440px;
}

.feature-icon-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 25px 0 0;
	align-items: center;
}

.feature-icon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 80px;
	width: 80px;
	border-radius: 5%;
	background-color: aliceblue;
}

.ant-spin-dot-item {
	background-color: #04b2a9 !important;
}

.feature-icon-img {
	width: 30px;
	height: 30px;
}

.heading-desc {
	font-weight: 500;
	margin-right: 35%;
	margin-top: 3%;
}

.cost {
	font-size: 30px;
	color: #04b2a9;
	font-weight: 600;
	padding-top: 5%;
}

.custom-button {
	width: 200px;
	height: 50px;
	background-color: white;
	font-size: 20px;
	color: #04b2a9;
}

.custom-button2 {
	width: 200px;
	height: 50px;
	background-color: #04b2a9;
	font-size: 20px;
	color: white;
}

.btn-zone1 .btn-zone2 {
	margin-top: 60px;
}

.custom-button2 {
	margin-top: 10px;
}

.custom-button2:hover {
	color: white !important;
	background-color: #049089;
}

.feature-icon-text {
	text-align: center;
	font-size: 11px;
	padding: 2px;
}

.pincode-input::-webkit-outer-spin-button,
.pincode-input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	margin: 0;
}

.product-info-tooltip {
	height: 15px;
	padding-top: 8%;
}

.product-info-text {
	margin-top: 8%;
	margin-left: 18.5rem;
	background-color: #049089;
	color: #fff;
	border-radius: 5px;
	padding: 10px;
	position: absolute;
	z-index: 100;
}

.book-demo-btn {
	height: 40px;
	width: 150px;
	font-size: 14px;
}

.ant-spin-dot-item-custom .ant-spin-dot-item {
	background-color: #fff !important;
}

@media only screen and (min-width: 1200px) {
	/* Styles for large screens */
}

@media only screen and (min-width: 1024px) {
	/* Styles for desktops */
	.product-img {
		width: 500px;
		height: auto;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	/* Styles for tablets */
	.product-img {
		width: 220px;
		height: auto;
	}
	.feature-icon {
		height: 60px;
		width: 60px;
	}
	.feature-icon-img {
		width: 20px;
		height: 20px;
	}
	.feature-icon-wrapper {
		margin: 0 10px 0 0;
	}
	.feature-name {
		font-size: 12px;
	}
	.btn-zone1 {
		margin: 10px 0;
	}
	.btn-zone2 {
		margin-top: 0px;
	}

	.custom-button2 {
		margin-bottom: 10px;
	}
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
	/* Styles for mobile devices */
	.product-img {
		padding-top: 40px;
	}
	.feature-icon {
		height: 60px;
		width: 60px;
	}
	.feature-icon-img {
		width: 20px;
		height: 20px;
	}
	.feature-icon-wrapper {
		margin: 0 10px 0 0;
	}
	.btn-zone1 {
		margin: 10px 0;
	}
	.btn-zone2 {
		margin-top: 0px;
	}

	.custom-button2 {
		margin-bottom: 10px;
	}
}

@media only screen and (max-width: 480px) {
	/* Styles for small screens */
	.btn-zone1 {
		margin: 10px 0;
	}
	.btn-zone2 {
		margin-top: 0px;
	}

	.custom-button2 {
		margin-bottom: 10px;
	}
	.feature-icon-wrapper {
		margin: 0 10px 0 0;
	}
	.feature-icon {
		height: 60px;
		width: 60px;
	}
	.feature-icon-wrapper:nth-child(3) {
		margin-top: 15px;
	}
	.feature-icon-wrapper:nth-child(4) {
		margin-right: 0;
	}
	.feature-name {
		font-size: 12px;
	}
	.feature-icon-img {
		width: 20px;
		height: 20px;
	}
	.product-img {
		padding-top: 40px;
		width: 250px;
		height: auto;
	}
	.heading-style {
		font-size: 2rem;
	}
}

@media only screen and (min-width: 400px) and (max-width: 480px) {
	/* Styles for small screens */
	.feature-icon-wrapper:nth-child(3) {
		margin-top: 0;
	}
}
