.compare-table-header {
	text-align: center;
	font-weight: 600;
	font-family: AvenirNextLTPro;
	color: #ffffff !important;
	padding: 10;
	margin: 30px 0px;
}

.compare-table-back-btn {
	cursor: pointer;
	font-size: 20px;
	margin-left: 30px;
}

.table-wrapper {
	width: 100%;
	display: block;
	color: #fff;
}

.table-wrapper .table-container {
	padding: 0 30px;
	overflow-x: auto;
}

.tg {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}

.tg td {
	border-style: none;
	font-family: AvenirNextLTPro;
	font-size: 14px;
	padding: 16px 5px;
}

.tg .table-data-help-label {
	width: 272px;
	position: sticky;
	left: 0;
}

.tg th {
	border-style: none;
	font-weight: normal;
	overflow: hidden;
	padding: 10px 5px;
	word-break: normal;
}

.tg .tg-0pky {
	border-color: inherit;
	border-bottom: 1px solid #f0f0f0;
	text-align: left;
	vertical-align: top;
}

.label {
	font-weight: 600;
}

.tg .tg-0lax {
	text-align: left;
	border-bottom: 1px solid #f0f0f0;
	width: 100px;
}

.image-wrapper {
	width: 200px;
	padding: 20px;
}

.image-wrapper .product-compare-image {
	width: 100%;
	object-fit: contain;
	object-position: center;
}

.image-wrapper .close-icons-mobile {
	right: 0%;
	top: 0%;
}
.product-image {
	width: 80%;
	object-fit: contain;
	object-position: center;
}
.select-product-container {
	width: 200px;
	height: 220px;
	border: 1px dashed #04b2a9;
	border-radius: 6;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
	margin-bottom: 18px;
	cursor: pointer;
	margin: 10px;
}

.select-product-container .select-product-inner-circle {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #9be7e3;
}

.select-product-container .select-product-inner-plus {
	color: #9be7e3;
	font-size: 40px;
	font-weight: bold;
	position: absolute;
	bottom: 20px;
	left: 43%;
}
.select-product-container {
	position: relative;
}

.table-data-label {
	cursor: pointer;
	font-family: AvenirNextLTPro;
	font-size: 16px !important;
	font-weight: 600;
	width: 250px !important;
	padding: 20px 0 !important;
	position: sticky;
	left: 0;
	white-space: nowrap;
	width: fit-content;
}

.table-data-help-value {
	padding: 0 40px !important;
}

.back-btn {
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);

	color: #000000;
	font-size: 13px;
	height: 40px;

	font-family: AvenirNextLTPro;
}

.anticon-close svg {
	color: #ffff !important;
	position: relative !important;
	top: -10px;
}
.back-btn:hover {
	border-color: #04b2a9 !important;
}

.confirm-btn {
	background: linear-gradient(183.98deg, #b0ffff 2.37%, #00ffff 95.82%);

	color: rgb(0, 0, 0);
	height: 40px;
	font-weight: 600;
	font-family: AvenirNextLTPro;
}

.confirm-btn:hover {
	border: 1.4px solid #04b2a9 !important;
}
.ant-modal-content {
	background-color: #1d1e18 !important;
	border: 1px solid #f2f2f2 !important;
}

.ant-modal-content p {
	display: none;
}
.prodct-list-to-compare::-webkit-scrollbar,
.table-container::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.prodct-list-to-compare {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.product-card-compare {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	gap: 10px;
	padding: 20px;
	min-width: 150px;
	max-width: 150px;
	border: 1px solid #f2f2f2;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
}
.close-icons-mobile {
	position: absolute;
	top: 10%;
	right: 10%;
	font-size: 20px;
}
.table-wrapper-small {
	display: none;
}
.product-card-compare p {
	font-size: 0.925rem;
	font-weight: 500;
	line-height: 0px;
	text-align: start;
	user-select: none;
}
.image-product {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70px;
	margin: auto;
}
.product-name {
	font-size: 1rem;
	line-height: 0px;
	text-align: start;
	color: #fff;
}
.product-list-compare {
	display: flex;
	gap: 1rem;
	max-width: 480px;
	overflow-x: auto;
	margin-right: 1rem;
}

@media only screen and (max-width: 480px) {
	.image-product {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 120px;
		margin: auto;
	}
	.compare-table-header {
		text-align: center;
		padding: 0;
		font-size: 20px !important;
	}

	.compare-table-back-btn {
		font-size: 18px;
		margin-left: 15px;
	}

	.table-wrapper .table-container {
		padding: 0;
	}

	.tg {
		overflow-x: auto; /* Enable horizontal scroll */
	}

	.image-wrapper .product-compare-image {
		width: 75%;
	}

	.table-data-label {
		background-color: #f9f9f9;
		font-size: 14px !important;
		z-index: 1;
		padding: 20px 10px !important;
		max-width: 100px;
		white-space: normal;
		display: flex;
		align-items: center;
		width: 100%;
	}

	.table-data-label.table-height {
		height: 250px !important;
	}

	.table-data-help-label {
		background-color: #f9f9f9;
		font-size: 12px;
		white-space: normal;
		padding: 20px 15px !important;
	}

	.image-cell {
		width: 100px !important;
	}
}

@media only screen and (max-width: 767px) {
	.table-data-help-label {
		width: 100px;
		font-size: 14px;
		font-weight: 500;
		color: #000000;
		text-transform: capitalize;
	}
	.table-wrapper {
		display: none;
	}
	.table-wrapper-small {
		display: block;
		box-sizing: border-box;
		overflow-x: hidden;
		width: 100%;
	}
	.table-data-help-value {
		width: fit-content;
		font-size: 14px;
		max-width: 100px;
		text-wrap: nowrap;
	}
	/* add css for tablet devices */
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.table-wrapper .table-container {
		padding: 0;
	}

	.table-data-label {
		background-color: #f9f9f9;
		font-size: 16px !important;
		padding: 20px 10px !important;
		max-width: 220px;
		width: 100%;
		white-space: normal;
		display: flex;
		align-items: center;
	}

	.table-data-label.table-height {
		height: 250px !important;
	}
	.product-list-compare {
		display: flex;
		gap: 1rem;
		max-width: 480px;
		overflow-x: auto;
		margin-right: 1rem;
	}

	.table-data-help-label {
		background-color: #f9f9f9;
		font-size: 12px;
		white-space: normal;
		padding: 20px 15px !important;
		max-width: 250px !important;
		width: 100%;
	}
}
