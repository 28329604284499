/* container */
.first-main-container {
	background-color: #000000;
	padding: 0px 150px;
	height: 65vh;
	align-items: center;
}

/* left container */
.first-left-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0% 8%;
}

.first-left-sub-container {
	gap: 25px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.first-title {
	color: #ffffff;
	/* font-family: 'M PLUS Rounded 1c', sans-serif; */
	font-size: 60px;
	font-weight: 600;
	line-height: 60px;
	font-family: AvenirNextLTPro;
	letter-spacing: 2px;
	text-shadow: none;

	transition: all 0.6s linear; /* vendorless fallback */
	-o-transition: all 0.6s linear; /* opera */
	-ms-transition: all 0.6s linear; /* IE 10 */
	-moz-transition: all 0.6s linear; /* Firefox */
	-webkit-transition: all 0.6s linear; /*safari and chrome */
}

/* .first-title:hover {
	text-shadow: 0 0 5px rgb(136, 244, 148), 0 0 10px rgb(136, 244, 148),
		0 0 20px rgb(136, 244, 148), 0 0 30px rgb(136, 244, 148);
} */

.first-subtitle {
	color: #ffffff;
	/* font-family: 'M PLUS Rounded 1c', sans-serif; */
	font-size: 20px !important;
	font-weight: 400;
	line-height: 30px;
	font-family: AvenirNextLTPro;
}

.first-description {
	color: #000000;
	font-size: 16px;
	line-height: 28px;
	font-weight: 400;
	font-family: AvenirNextLTPro;
}

.first-explore-btn-gred {
	background: #00ffff;
	color: #000;
	font-size: 14px;
	font-weight: bold;
	height: 40px;
	border: 1px solid #f7f7f7;
	font-family: AvenirNextLTPro;
	width: 220px;
	font-weight: 600;
}

.first-explore-btn-gred:hover {
	border: 1px solid #04b2a9 !important;
	color: #000;
}

/* right container*/
.vector1 {
	width: 450px !important;
	height: 450px !important;
}

.vector2 {
	position: absolute;
	top: -235px;
	left: -450px;
	width: 450px !important;
	height: 450px !important;
}

.first-left-container-home {
	padding: 100px 0px;
}

.first-right-container {
	text-align: center;
	align-self: center;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	/* Styles for tablets */
	.vector1 {
		width: 250px !important;
		height: 250px !important;
	}

	.vector2 {
		position: absolute;
		top: -128px;
		left: -250px;
		width: 250px !important;
		height: 250px !important;
	}

	.first-main-container {
		padding: 25px 25px;
	}
	.first-main-container {
		height: 50vh;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	/* Styles for tablets */
	.vector1 {
		width: 250px !important;
		height: 250px !important;
	}

	.vector2 {
		position: absolute;
		top: -128px;
		left: -250px;
		width: 280px !important;
		height: 280px !important;
	}

	.first-main-container {
		padding: 25px 40px;
	}
}

@media screen and (max-width: 768px) {
	.first-main-container {
		height: fit-content;
	}

	.first-main-container {
		padding: 50px 30px;
	}
	.first-explore-btn-gred {
		margin-top: 14px;
	}

	.first-title {
		font-size: 28px;
		line-height: 35px;
	}

	.first-left-container-home {
		gap: 20px;
	}

	.first-subtitle {
		font-size: 18px;
	}

	.first-description {
		line-height: 25px;
	}

	.first-right-container {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 45px 0px;
	}

	.first-explore-btn {
		width: 50%;
	}

	.first-left-container {
		padding: 40px;
	}

	.vector1 {
		width: 300px !important;
		height: 300px !important;
	}

	.vector2 {
		position: absolute;
		top: -128px;
		left: -250px;
		width: 250px !important;
		height: 250px !important;
	}
}

@media screen and (min-width: 576px) {
	/* .first-main-container {
        height: 45vh;
    } */

	.first-title {
		font-size: 38px;
	}

	.first-explore-btn {
		width: 50%;
	}

	.first-left-container {
		padding: 0% 4%;
	}
}

@media screen and (min-width: 860px) {
	.first-explore-btn {
		width: 40%;
	}

	.first-left-container {
		padding: 0px;
	}

	.first-title {
		font-size: 47px;
	}
}
