/* @import url('https://fonts.cdnfonts.com/css/avenir-lt-pro'); */
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';


@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {


	font-family: 'AvenirNextLTPro';
	src: local('AvenirNextLTPro'),
		url('./assets/fonts/AvenirLTProBook.woff') format('woff');
}

@font-face {
	font-family: 'Helvetica';
	src: local('Helvetica'), url('./assets/fonts/Helvetica.ttf') format('ttf');
}

body {
	margin: 0;
	font-family: AvenirNextLTPro;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #1d1e18;
}

.ant-drawer .ant-drawer-title {
	color: #fff !important;
}
.title {
	color: #ffff !important;
}
.bold-text {
	color: #fff !important;
}
.item-count {
	color: #000000 !important;
}
.ant-drawer .ant-drawer-content {
	background-color: #1d1e18 !important;
}
.filter-btn,
.sort-btn {
	background-color: #1d1e18 !important;
}

.ant-upload-icon .anticon {
	color: #fff !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

::-webkit-scrollbar {
	width: 7px;
}

article {
	color: #fff !important;
}

aside {
	background-color: #1d1e18 !important;
}

.ant-collapse-header-text,
.ant-menu-title-content {
	color: #ffff !important;
}

strong,
label,
.ant-breadcrumb-link > a > span {
	color: #fff !important;
}


.ant-btn > .ant-space > .ant-space-item {
	color: #000000 !important;
}

.ant-layout {
	background-color: #1d1e18 !important;
}

::-webkit-scrollbar-track {
	background-color: #fff;
}

::-webkit-scrollbar-thumb {
	background-color: #00ffff;
	/* border-radius: 20px; */
}
.ant-upload-wrapper
	.ant-upload-list
	.ant-upload-list-item
	.ant-upload-list-item-actions
	.anticon {
	color: #00ffffff !important;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #00ffff;
}
.ant-collapse-item {
	border: 1px solid #00ffff;
}

.anticon-mail,
.contact-info-text,
.anticon-global,
.anticon-environment,
.ant-space-item,
.ant-empty-description,
.address-card,
.ant-upload-list-item-container,
.checkout-card-text,
.ant-typography-secondary {
	color: #fff !important;
}

.select-address-btn {
	color: #000000 !important;
}

.anticon-left {
	color: #000000;
}

.ant-breadcrumb-link {
	color: #00ffff !important;
}
::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	margin: 0;
}

@media only screen and (max-width: 767px) {
	.modal {
		width: 90% !important;
	}
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.lily-pad-color{
  color: #04B2A9;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
